import React, { useContext, useEffect, useRef, useState } from "react";
import { MdUnarchive } from "react-icons/md";
import ContactCard from "../../components/ContactCard";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { AuthContext } from "../../../context/AuthContext";
import { FiX } from "react-icons/fi";

function ExpandedContactCardViewArchive({
  contacts,
  setArchivedContacts,
  currentAvatarPreferences,
}) {
  const modalRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const [unarchiveAllModal, setUnarchiveAllModal] = useState(false);

  // Handle clicks outside modals and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setUnarchiveAllModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setUnarchiveAllModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Unarchive all contacts
  const handleUnarchiveAll = () => {
    if (!contacts || contacts.length === 0) {
      alert("No contacts available to unarchive");
      setUnarchiveAllModal(false);
      return;
    }
    try {
      contacts.forEach(async (contact) => {
        await updateDoc(doc(db, `contacts-${currentUser.email}`, contact.id), {
          contactStatus: "active",
        });
      });
      setArchivedContacts([]);
      alert("All contacts have been unarchived");
    } catch (error) {
      alert("There was an error. Contact website support for help.");
    }
    setUnarchiveAllModal(false);
  };

  return (
    <div className="flex-1 h-[calc(100vh-1.8rem)] p-4 border border-gray-200 relative custom-scrollbar">
      <div className="flex justify-between">
        <h2 className="text-xl ml-2 mt-2 font-medium">Archived Contacts</h2>
        <button
          className="flex p-2 list-none text-gray-700 font-medium cursor-pointer rounded-lg border border-gray-200 shadow shadow-gray-200 hover:bg-blue-500 hover:text-white duration-200 ease-in-out"
          onClick={() => setUnarchiveAllModal(true)}
        >
          <MdUnarchive className="mt-[0.15rem] mr-1 text-lg" />
          Unarchive all
        </button>
      </div>
      <ContactCard
        contacts={contacts}
        currentContactAvatarPreference={currentAvatarPreferences}
      />

      {/* Unarchive modal */}
      {unarchiveAllModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Unarchive Notes
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setUnarchiveAllModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 border border-gray-200" />
            <div className="items-center justify-center text-gray-600 mt-4">
              <p className="mb-2 text-center">
                Doing this will
                <span className="font-semibold"> unarchive</span> all the
                contacts.
              </p>
              <button
                className="flex text-md font-medium p-2 mx-auto w-full md:w-auto rounded hover:bg-blue-500 hover:text-white ease-in-out duration-200"
                onClick={() => handleUnarchiveAll()}
              >
                <MdUnarchive className="mt-[0.24rem] mr-1" />
                Unarchive All Contacts
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExpandedContactCardViewArchive;
