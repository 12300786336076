import { FaInstagram, FaLinkedin, FaSnapchatGhost } from "react-icons/fa";
import { FaThreads, FaXTwitter } from "react-icons/fa6";
import { IoLogoGithub } from "react-icons/io5";

function SocialBlocks({ content }) {
  const hasSocials =
    content?.instagram ||
    content?.snapchat ||
    content?.linkedin ||
    content?.threads ||
    content?.x ||
    content?.github;

  return (
    <div className="flex space-x-3">
      {hasSocials ? (
        <>
          {/* Instagram */}
          {content?.instagram && (
            <button
              className="flex justify-center items-center text-xl w-[calc(100%-15.5rem)] bg-gradient-to-r from-yellow-400 via-pink-500 to-purple-500 p-2 rounded-lg group overflow-hidden relative"
              onClick={() => {
                const url = content.instagram.startsWith("http")
                  ? content.instagram
                  : `https://${content.instagram}`;
                window.open(url, "_blank", "noopener,noreferrer");
              }}
            >
              <FaInstagram className="text-white" />
            </button>
          )}

          {/* Snapchat */}
          {content?.snapchat && (
            <button
              className="flex justify-center items-center text-xl w-[calc(100%-15.5rem)] bg-yellow-400 p-2 rounded-lg group overflow-hidden relative"
              onClick={() => {
                navigator.clipboard.writeText(content.snapchat);
                alert("Copied to clipboard!");
              }}
            >
              <FaSnapchatGhost className="text-white" />
            </button>
          )}

          {/* LinkedIn */}
          {content?.linkedin && (
            <button
              className="flex justify-center items-center text-xl w-[calc(100%-15.5rem)] bg-blue-500 p-2 rounded-lg group overflow-hidden relative"
              onClick={() => {
                const url = content.linkedin.startsWith("http")
                  ? content.linkedin
                  : `https://${content.linkedin}`;
                window.open(url, "_blank", "noopener,noreferrer");
              }}
            >
              <FaLinkedin className="text-white" />
            </button>
          )}

          {/* Threads */}
          {content?.threads && (
            <button
              className="flex justify-center items-center text-xl w-[calc(100%-15.5rem)] bg-black p-2 rounded-lg group overflow-hidden relative"
              onClick={() => {
                const url = content.threads.startsWith("http")
                  ? content.threads
                  : `https://${content.threads}`;
                window.open(url, "_blank", "noopener,noreferrer");
              }}
            >
              <FaThreads className="text-white" />
            </button>
          )}

          {/* X (Twitter) */}
          {content?.x && (
            <button
              className="flex justify-center items-center text-xl w-[calc(100%-15.5rem)] bg-black p-2 rounded-lg group overflow-hidden relative"
              onClick={() => {
                const url = content.x.startsWith("http")
                  ? content.x
                  : `https://${content.x}`;
                window.open(url, "_blank", "noopener,noreferrer");
              }}
            >
              <FaXTwitter className="text-white" />
            </button>
          )}

          {/* GitHub */}
          {content?.github && (
            <button
              className="flex justify-center items-center text-xl w-[calc(100%-15.5rem)] bg-black p-2 rounded-lg group overflow-hidden relative"
              onClick={() => {
                const url = content.github.startsWith("http")
                  ? content.github
                  : `https://${content.github}`;
                window.open(url, "_blank", "noopener,noreferrer");
              }}
            >
              <IoLogoGithub className="text-white" />
            </button>
          )}
        </>
      ) : (
        <span className="w-full text-center text-gray-500 italic">
          No socials added
        </span>
      )}
    </div>
  );
}

export default SocialBlocks;
