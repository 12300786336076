import { FiX } from "react-icons/fi";
import { db } from "../../../firebase";
import { FaPlus } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import SearchBar from "../../components/SearchBar";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation } from "react-router-dom";
import ContactCard from "../../components/ContactCard";
import { AuthContext } from "../../../context/AuthContext";
import { Base64Util } from "../../../utils/B64EncodeAndDecode";
import { useContext, useEffect, useRef, useState } from "react";
import { addDoc, collection, updateDoc } from "firebase/firestore";

function ExpandedContactCardView({
  contacts,
  currentSystemEncryption,
  currentContactAvatarPreference,
}) {
  let location = useLocation();
  const modalRef = useRef(null);
  const [birthday, setBirthday] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [newContactModal, setNewContactModal] = useState(false);

  // Handle clicks outside modals and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setNewContactModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setNewContactModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Assign random color for contact avatar
  const assignRandomColor = () => {
    const colors = ["green", "red", "blue", "purple", "pink"];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    return randomColor;
  };

  // Handle new contact submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const collectionTitle = "contacts-" + currentUser.email;

    try {
      assignRandomColor();
      // Save data based on currentSystemEncryption
      const contactData =
        currentSystemEncryption === "base64"
          ? {
              // When currentSystemEncryption is base64
              contactStatus: "active",
              phone: Base64Util.encode(contactPhone),
              email: Base64Util.encode(contactEmail),
              address: Base64Util.encode(contactAddress),
              contactEncryption: currentSystemEncryption,
              lastName: Base64Util.encode(contactLastName),
              firstName: Base64Util.encode(contactFirstName),
              birthday: birthday
                ? Base64Util.encode(birthday.toISOString())
                : null,
              avatarColor: assignRandomColor(),
            }
          : {
              // When currentSystemEncryption is none
              birthday: birthday,
              phone: contactPhone,
              email: contactEmail,
              contactStatus: "active",
              address: contactAddress,
              lastName: contactLastName,
              firstName: contactFirstName,
              avatarColor: assignRandomColor(),
            };

      // Add a new document with a generated ID
      const docRef = await addDoc(collection(db, collectionTitle), contactData);

      // Update the document with its own ID
      await updateDoc(docRef, { id: docRef.id });

      alert("Contact has been saved");
      setNewContactModal(false);
    } catch (err) {
      console.error("Error adding document: ", err);
    }
  };

  return (
    <div className="flex-1 h-[calc(100vh-1.8rem)] p-4 border border-gray-200 relative custom-scrollbar">
      {location.pathname === "/starconnect/contacts" ? (
        <>
          {/* Topbar */}
          <div className="flex items-center justify-between min-w-[calc(100vw-43rem)] space-y-4 md:space-y-0 md:space-x-4">
            <h2 className="text-base sm:text-base sm:text-center md:text-xl ml-1 font-medium w-full md:w-auto">
              All Contacts
            </h2>
            <div className="w-full md:w-auto mx-auto md:mx-0">
              <SearchBar />
            </div>
            <button
              className="flex items-center p-2 sm:p-3 bg-gray-100 text-gray-800 rounded-3xl hover:shadow hover:shadow-yellow-200 transition duration-200 mt-4 md:mt-0"
              title="Create a new contact"
              onClick={() => setNewContactModal(true)}
            >
              <FaPlus className="text-lg sm:text-xl text-yellow-400" />
              <span className="ml-1 sm:hidden md:text-sm lg:inline">
                New Contact
              </span>
            </button>
          </div>
          <ContactCard
            contacts={contacts}
            currentContactAvatarPreference={currentContactAvatarPreference}
          />

          {/* New contact modal */}
          {newContactModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
              <div
                ref={modalRef}
                className="relative text-gray-700 bg-white rounded-lg shadow-md p-4 w-[25%]"
              >
                <div className="flex justify-between items-center mb-3 px-1">
                  <h2 className="text-lg font-semibold text-gray-700">
                    Add Contact
                  </h2>
                  <FiX
                    className="text-lg cursor-pointer text-gray-700"
                    onClick={() => setNewContactModal(false)}
                    title="Close"
                  />
                </div>
                <div className="-mx-4 border bg-white" />
                {/* Contact form */}
                <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                  {/* Profile image and name fields */}
                  <div className="flex items-center mb-4 mx-4">
                    <img
                      src="https://via.placeholder.com/80"
                      alt="Profile"
                      className="w-16 h-16 rounded-full mr-6"
                    />
                    <div className="w-full space-y-2">
                      <input
                        type="text"
                        placeholder="First Name"
                        className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={(e) => setContactFirstName(e.target.value)}
                        value={contactFirstName}
                        required
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={(e) => setContactLastName(e.target.value)}
                        value={contactLastName}
                      />
                    </div>
                  </div>

                  {/* Email */}
                  <div>
                    <label className="block mb-1 font-medium">Email</label>
                    <input
                      type="email"
                      className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Email"
                      onChange={(e) => setContactEmail(e.target.value)}
                      value={contactEmail}
                    />
                  </div>

                  {/* Phone */}
                  <div>
                    <label className="block mb-1 font-medium">Phone</label>
                    <input
                      type="tel"
                      className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Phone"
                      onChange={(e) => setContactPhone(e.target.value)}
                      value={contactPhone}
                    />
                  </div>

                  {/* Address */}
                  <div>
                    <label className="block mb-1 font-medium">Address</label>
                    <input
                      type="text"
                      className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Address"
                      onChange={(e) => setContactAddress(e.target.value)}
                      value={contactAddress}
                    />
                  </div>

                  {/* Birthday */}
                  <div>
                    <label className="block mb-1 font-medium">Birthday</label>
                    <DatePicker
                      selected={birthday}
                      className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onChange={(bDate) => setBirthday(bDate)}
                      placeholderText="MM/DD/YYYY"
                    />
                  </div>

                  {/* Save button */}
                  <button
                    type="submit"
                    className="w-full py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          )}
        </>
      ) : (
        // Resting info page
        <div className="flex justify-center items-center min-h-[calc(100vh-2rem)]">
          <div className="text-center">
            <h1 className="text-4xl font-semibold text-gray-800 mb-8">
              Connections, secured
            </h1>
            <ul className="space-y-4 text-lg">
              <li>
                <Link to="/settings/starconnect" className="text-blue-500">
                  Manage Settings
                </Link>
              </li>
              <li>
                <Link to="/starconnect/archive" className="text-blue-500">
                  See Archive
                </Link>
              </li>
              <li>
                <Link to="/documentation/starconnect" className="text-blue-500">
                  See Docs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExpandedContactCardView;
