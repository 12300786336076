import {
  doc,
  where,
  query,
  getDocs,
  updateDoc,
  collection,
} from "firebase/firestore";
import { db } from "../../firebase";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GoLinkExternal } from "react-icons/go";
import { AuthContext } from "../../context/AuthContext";
import { MdLabelOutline, MdOutlineArchive } from "react-icons/md";
import { PiCardsThreeLight, PiListBulletsBold } from "react-icons/pi";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaUserCircle, FaRegUserCircle, FaHashtag } from "react-icons/fa";

function StarConnectSettings({ isNavOpen }) {
  const modalRef = useRef(null);
  const [docId, setDocId] = useState();
  const { currentUser } = useContext(AuthContext);
  const [pendingState, setPendingState] = useState(false);
  const [CDSPendingState, setCDSPendingState] = useState(false);
  const [
    avatarPreferenceConfirmationModal,
    setAvatarPreferenceConfirmationModal,
  ] = useState(false);
  const [currentContactDisplaySetting, setCurrentContactDisplaySetting] =
    useState("");
  const [currentAvatarPreferenceSetting, setCurrentAvatarPreferenceSetting] =
    useState("");
  const [contactDisplayConfirmationModal, setContactDisplayConfirmationModal] =
    useState("");

  // Manage closing modals on outside click or 'ESC' key press
  useEffect(() => {
    const closeAllModals = () => {
      setAvatarPreferenceConfirmationModal(false);
      setContactDisplayConfirmationModal(false);
    };

    // Handle clicks outside the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeAllModals();
      }
    };

    // Handle Escape key press
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeAllModals();
      }
    };

    // Add event listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setAvatarPreferenceConfirmationModal, modalRef]);

  // Fetch user's doc
  useEffect(() => {
    const fetchData = async () => {
      const docIdQuery = query(
        collection(db, "users"),
        where("email", "==", currentUser.email)
      );
      const querySnapshot = await getDocs(docIdQuery);
      querySnapshot.forEach((doc) => {
        setDocId(doc.id);
        setCurrentAvatarPreferenceSetting(
          doc.data().contactAvatarPreference ?? "filled-color"
        );
        setCurrentContactDisplaySetting(doc.data().contactDisplayStyle);
      });
    };

    fetchData();
  }, [currentUser.email]);

  // Handle changes in avatar preferences
  const handleAvatarPreferenceSettingChange = async (newState) => {
    try {
      const docRef = doc(db, "users", docId);
      await updateDoc(docRef, { contactAvatarPreference: newState });
      setCurrentAvatarPreferenceSetting(newState);
      setPendingState(newState);
    } catch (error) {
      alert("An error occurred while updating your preferences");
    }
  };

  // Handle toggle action for avatar preference
  const handleAvatarPreferenceToggle = (checked) => {
    setPendingState(checked ? "gradient-ring" : "filled-color");
    setAvatarPreferenceConfirmationModal(true);
  };

  // Confirm the avatar preference toggle action and update db
  const confirmAvatarPreferenceToggle = async () => {
    await handleAvatarPreferenceSettingChange(pendingState);
    setAvatarPreferenceConfirmationModal(false);
  };

  // Handle changes in contact display style
  const handleContactDisplaySettingChange = async (newStateCDS) => {
    try {
      const docRef = doc(db, "users", docId);
      await updateDoc(docRef, { contactDisplayStyle: newStateCDS });
      setCurrentContactDisplaySetting(newStateCDS);
      setCDSPendingState(newStateCDS);
    } catch (error) {
      alert(
        "An error occurred while updating your contact display style preferences"
      );
    }
  };

  // Handle toggle action for contact display style
  const handleContactDisplayToggle = async (checked) => {
    setCDSPendingState(checked ? "card" : "list");
    setContactDisplayConfirmationModal(true);
  };

  // Confirm the contact display style toggle action and update db
  const confirmContactDisplayToggle = async () => {
    await handleContactDisplaySettingChange(CDSPendingState);
    setContactDisplayConfirmationModal(false);
  };

  return (
    <div
      className={`pl-2 pr-5 pb-4 -mr-6 -mt-5 space-y-6 overflow-y-auto custom-scrollbar ${
        isNavOpen ? "w-[calc(100vw-35rem)]" : "w-[calc(100vw-22rem)]"
      } h-[calc(100vh-2.5rem)]`}
    >
      <h2 className="my-4 text-xl font-medium">Star Connect</h2>

      {/* Contact avatar preferences */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">
          Contact Avatar Preferences
        </h3>
        <p className="text-md text-gray-600 mb-4">
          Choose whether to see contact avatars with colored backgrounds or
          white backgrounds with a gradient ring for encrypted contacts.
        </p>
        <div className="flex space-x-4">
          {/* Sec-1 */}
          <div className="w-1/2 p-4 border border-gray-300 rounded-lg text-center transition-shadow duration-300">
            <h3 className="text-left text-lg font-medium mb-3">Filled Color</h3>
            <div className="flex">
              {/* Circle-1 */}
              <div className="flex-shrink-0 bg-blue-500 w-24 h-24 rounded-full">
                <span className="text-white text-4xl font-semibold flex items-center justify-center h-full">
                  AB
                </span>
              </div>
              {/* Circle-2 */}
              <div className="flex-shrink-0 bg-purple-500 ml-4 w-24 h-24 rounded-full">
                <span className="text-white text-4xl font-semibold flex items-center justify-center h-full">
                  CD
                </span>
              </div>
              <p className="text-sm ml-4 text-gray-800">
                When{" "}
                <b>
                  <i>Filled Color</i>
                </b>{" "}
                is selected, contact avatars will have randomly assigned colors,
                which can be changed later. To check if a contact is encrypted,
                you may refer to the banner in the top-right after selecting the
                contact.
              </p>
            </div>
          </div>

          {/* Sec-2 */}
          <div className="w-1/2 p-4 border border-gray-300 rounded-lg text-center transition-shadow duration-300">
            <h3 className="text-left text-lg font-medium mb-4">
              Gradient Ring
            </h3>
            <div className="flex">
              {/* Circle-1 */}
              <div className="flex-shrink-0 bg-transparent w-24 h-24 rounded-full border-2 border-gray-300">
                <span className="text-gray-600 text-4xl font-semibold flex items-center justify-center h-full">
                  AB
                </span>
              </div>

              {/* Circle-2 */}
              <div className="relative w-24 h-24 ml-4 flex-shrink-0 rounded-full bg-transparent">
                <div
                  className="absolute inset-0 rounded-full bg-gradient-to-r from-yellow-200 via-purple-400 to-blue-500"
                  style={{
                    padding: "3px",
                  }}
                >
                  <div className="w-full h-full bg-white rounded-full flex items-center justify-center text-lg font-bold">
                    <span className="text-gray-600 text-4xl font-semibold flex items-center justify-center h-full">
                      CD
                    </span>
                  </div>
                </div>
              </div>
              <p className="text-sm ml-4 text-gray-800">
                When{" "}
                <b>
                  <i>Gradient Ring</i>
                </b>{" "}
                is selected, the avatars of non-encrypted contacts will appear
                with a white/transperent background(<i>See left circle</i>),
                while avatars of encrypted contacts will appear with a gradient
                ring around them(<i>See right circle</i>)
              </p>
            </div>
          </div>
        </div>
        {/* CA preferences toggle */}
        <div className="flex items-center justify-between mt-5 mb-1 mr-2">
          <div className="flex items-center ml-auto">
            <span className="text-sm text-gray-600">Filled</span>
            <label className="relative mx-2 inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={currentAvatarPreferenceSetting === "gradient-ring"}
                onChange={(e) => handleAvatarPreferenceToggle(e.target.checked)}
                disabled={
                  !["filled-color", "gradient-ring", undefined, null].includes(
                    currentAvatarPreferenceSetting
                  )
                }
                title={
                  currentAvatarPreferenceSetting !== "filled-color" &&
                  currentAvatarPreferenceSetting !== "gradient-ring"
                    ? "Error"
                    : ""
                }
              />
              <div
                className={`w-11 h-6 rounded-full peer peer-focus:ring-2 transition-all 
          ${
            currentAvatarPreferenceSetting === "filled-color" ||
            currentAvatarPreferenceSetting === "gradient-ring"
              ? "bg-gradient-to-r from-[#c1a7f4] to-[#3b82f6] peer-checked:bg-indigo-500"
              : "bg-gray-300"
          }`}
              />
              <div
                className={`absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow transition-transform 
          ${
            currentAvatarPreferenceSetting === "gradient-ring"
              ? "peer-checked:translate-x-5"
              : ""
          } ${
                  currentAvatarPreferenceSetting !== "filled-color" &&
                  currentAvatarPreferenceSetting !== "gradient-ring"
                    ? "bg-gray-500"
                    : ""
                }`}
              />
            </label>
            <span className="text-sm text-gray-600">Ring</span>
          </div>
        </div>
      </div>

      {/* Contact avatar preference confirmation modal */}
      {avatarPreferenceConfirmationModal && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[25%]"
          >
            <div className="flex justify-between items-center text-lg mb-3 px-1">
              <h2 className="font-semibold text-gray-800 ">
                Update Contact Avatar Preferences
              </h2>
              <FiX
                className="text-gray-700 cursor-pointer"
                onClick={() => setAvatarPreferenceConfirmationModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-white" />
            <div className="text-center">
              <p className="text-gray-600 mb-2">
                Are you sure you want to change your contact avatar preferences
                to{" "}
                <span className="italic font-medium">
                  {currentAvatarPreferenceSetting === "gradient-ring"
                    ? "filled color"
                    : currentAvatarPreferenceSetting === "filled-color"
                    ? "gradient ring"
                    : null}
                  ?
                </span>
              </p>
              <button
                className={`flex text-md text-gray-600 mx-auto font-medium p-2 rounded ${
                  currentAvatarPreferenceSetting === "gradient-ring"
                    ? "hover:bg-blue-500"
                    : "hover:bg-gradient-to-r from-[#9f7aea] to-[#3b82f6]"
                } hover:text-white ease-in-out duration-200`}
                onClick={confirmAvatarPreferenceToggle}
              >
                <FaUserCircle className="my-auto mr-1" />
                Set Preference to{" "}
                {currentAvatarPreferenceSetting === "gradient-ring"
                  ? "Filled Color"
                  : currentAvatarPreferenceSetting === "filled-color"
                  ? "Gradient ring"
                  : null}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Contact display */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">
          Contact Display
        </h3>
        <p className="text-md text-gray-600 mb-4">
          Choose how your contacts are displayed in Star Connect. You can switch
          between a compact list view or a visually appealing card view.
        </p>
        <div className="flex space-x-4">
          {/* List view */}
          <div className="w-1/2 p-4 border border-gray-300 rounded-lg text-center transition-shadow duration-300">
            <h3 className="text-left text-lg font-medium mb-3">List View</h3>
            <div className="h-48 w-[21.5rem] bg-[#f7f7fb] mx-auto border border-gray-400 rounded-lg relative">
              {/* Star Connect Text */}
              <span className="absolute top-1 left-2 font-semibold text-[10px]">
                Star Connect
              </span>

              {/* Horizontal Lines and Gray Boxes */}
              <div className="flex flex-col items-start pt-6 space-y-3">
                <div className="w-24 h-[1px] bg-gray-400" />
                <div className="w-20 h-3 ml-2 bg-gray-300 rounded-lg" />
                <div className="w-24 h-[1px] bg-gray-400" />
                <div className="w-20 h-3 ml-2 bg-gray-300 rounded-lg" />
                <div className="w-24 h-[1px] bg-gray-400" />
                <div className="w-20 h-3 ml-2 bg-gray-300 rounded-lg" />
                <div className="w-24 h-[1px] bg-gray-400" />
                <div className="w-20 h-3 ml-2 bg-gray-300 rounded-lg" />
              </div>

              {/* Vertical Line and Right Content */}
              <div className="absolute top-0 left-[6rem] flex items-center">
                <div className="h-[11.9rem] w-[1px] bg-gray-400" />
                <div className="ml-14">
                  <span className="font-medium text-xs">
                    Connections, Secured
                  </span>
                  <ul className="text-[10px] text-blue-500">
                    <li>Manage Settings</li>
                    <li>See Archive</li>
                    <li>See Docs</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Card view */}
          <div className="w-1/2 p-4 border border-gray-300 rounded-lg text-center transition-shadow duration-300">
            <h3 className="text-left text-lg font-medium mb-4">Card View</h3>
            <div className="h-48 w-[21.5rem] bg-[#f7f7fb] mx-auto border border-gray-400 rounded-lg">
              <span
                className={`font-semibold text-[10px] ${
                  isNavOpen ? "-ml-[16rem]" : "-ml-[26rem]"
                }`}
              >
                Star Connect
              </span>
              <div className="w-24 h-[0.05rem] bg-gray-400 my-1" />
              <span className="flex text-[0.50rem] ml-1 mb-1">
                <FaRegUserCircle className="mt-[0.10rem] mr-1" /> All Contacts
              </span>
              <span className="flex text-[0.50rem] ml-1">
                <MdOutlineArchive className="mt-[0.10rem] mr-1" /> Archive
              </span>
              <div className="w-24 h-[0.05rem] bg-gray-400 my-1" />
              <span className="flex w-[5.9rem] items-center text-[0.50rem] p-[0.15rem] bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 cursor-pointer duration-200 ease-in-out">
                <MdLabelOutline className="mr-1 ml-[0.15rem]" />
                <span>Labels</span>
                <span className="text-[0.40rem] ml-3 text-blue-500">
                  Edit Labels
                </span>
              </span>
              <div className="flex flex-col gap-1 ml-4 mt-1">
                <div className="flex items-center">
                  <FaHashtag className="text-[0.45rem] text-gray-600" />
                  <div className="w-14 h-2 bg-gray-300 rounded-lg ml-1" />
                </div>
                <div className="flex items-center">
                  <FaHashtag className="text-[0.45rem] text-gray-600" />
                  <div className="w-14 h-2 bg-gray-300 rounded-lg ml-1" />
                </div>
                <div className="flex items-center">
                  <FaHashtag className="text-[0.45rem] text-gray-600" />
                  <div className="w-14 h-2 bg-gray-300 rounded-lg ml-1" />
                </div>
              </div>
              <div className="flex mt-2">
                <div className="h-[11.94rem] w-[0.05rem] bg-gray-400 -mt-[8.2rem] ml-24" />
                <div className="flex w-full -mt-[8rem] justify-center">
                  <div className="flex flex-col items-center">
                    <div className="flex space-x-3">
                      <div className="h-14 w-12 mt-4 pt-1 bg-gray-300 rounded-md" />
                      <div className="h-14 w-12 mt-4 pt-1 bg-gray-300 rounded-md" />
                      <div className="h-14 w-12 mt-4 pt-1 bg-gray-300 rounded-md" />
                      <div className="h-14 w-12 mt-4 pt-1 bg-gray-300 rounded-md" />
                    </div>
                    <div className="flex space-x-3">
                      <div className="h-14 w-12 mt-4 pt-1 bg-gray-300 rounded-md" />
                      <div className="h-14 w-12 mt-4 pt-1 bg-gray-300 rounded-md" />
                      <div className="h-14 w-12 mt-4 pt-1 bg-gray-300 rounded-md" />
                      <div className="h-14 w-12 mt-4 pt-1 bg-gray-300 rounded-md" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact display toggle */}
        <div className="flex items-center justify-between mt-5 mr-2 mb-23">
          <div className="flex items-center ml-auto">
            <span className="text-sm text-gray-600">List</span>
            <label className="relative mx-2 inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={currentContactDisplaySetting === "card"}
                onChange={(e) => handleContactDisplayToggle(e.target.checked)}
                disabled={
                  currentContactDisplaySetting !== "list" &&
                  currentContactDisplaySetting !== "card"
                }
                title={
                  currentContactDisplaySetting !== "filled-color" &&
                  currentContactDisplaySetting !== "gradient-ring"
                    ? "Error"
                    : ""
                }
              />
              <div
                className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 transition-all ${
                  currentAvatarPreferenceSetting === "filled-color" ||
                  currentAvatarPreferenceSetting === "gradient-ring"
                    ? "bg-gradient-to-r from-[#c1a7f4] to-[#3b82f6] peer-checked:bg-indigo-500"
                    : "bg-gray-300"
                }`}
              />
              <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
            </label>
            <span className="text-sm text-gray-600">Card</span>
          </div>
        </div>
      </div>

      {/* Contact display style confirmation modal */}
      {contactDisplayConfirmationModal && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[25%]"
          >
            <div className="flex justify-between items-center text-lg mb-3 px-1">
              <h2 className="font-semibold text-gray-800 ">
                {" "}
                Update Contact Display Style
              </h2>
              <FiX
                className="text-gray-700 cursor-pointer"
                onClick={() => setContactDisplayConfirmationModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-white" />
            <div className="text-center">
              <p className="text-gray-600 mb-2">
                Are you sure you want to change the contact-display style to{" "}
                <span className="italic font-medium">
                  {currentContactDisplaySetting === "card"
                    ? "list-view"
                    : currentContactDisplaySetting === "list"
                    ? "card-view"
                    : null}
                  ?
                </span>
              </p>
              <button
                className="flex text-md mx-auto font-medium text-gray-600 p-2 rounded hover:bg-blue-500 hover:text-white ease-in-out duration-300"
                onClick={confirmContactDisplayToggle}
              >
                {currentContactDisplaySetting === "card" ? (
                  <PiListBulletsBold className="my-auto mr-1" />
                ) : (
                  <PiCardsThreeLight className="my-auto mr-1" />
                )}
                Set Display Style to{" "}
                {currentContactDisplaySetting === "card"
                  ? "List View"
                  : currentContactDisplaySetting === "list"
                  ? "Card View"
                  : null}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Labels */}
      <div className="flex items-center p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700">Manage Labels</h3>
        <Link
          to="/settings"
          className="flex text-sm ml-auto hover:underline hover:text-blue-500"
        >
          Manage labels
          <GoLinkExternal className="my-auto ml-1" />
        </Link>
      </div>

      {/* Call/Message icons */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">
          Call/Mesage shortcuts <i>(list-view)</i>
        </h3>
        <p className="text-md text-gray-600 mb-4">
          Choose whether you want to see the call/message shortcuts next to the
          contact name in the sidebar. This setting is only applied if you're
          using <i>(list-view)</i>
        </p>
        ILLUSTRATION HERE
        {/* Current Font */}
        <div className="flex justify-between items-center">
          <p className="text-md mt-8 font-medium underline underline-offset-2 text-gray-600">
            Current setting:
            <span className="font-semibold inline-block ml-1">ON</span>
          </p>
          <button className="px-3 py-2 mt-3 font-medium rounded duration-150 ease-in-out cursor-pointer shadow hover:shadow-indigo-500 bg-gray-200 text-gray-700">
            Save Shortcut Setting
          </button>
        </div>
      </div>

      {/* Birthday Notifications */}
      <div className="flex p-4 border border-gray-200 rounded-lg shadow-md">
        <div className="block">
          <h3 className="text-lg font-semibold text-gray-700">
            Birthday Notifications
          </h3>
          <p className="text-md text-gray-600">
            When one of your contacts has their birthday, do you want to receive
            a notification/reminder?
          </p>
        </div>
        <div className="flex items-center ml-auto space-x-4">
          <span className="text-sm text-gray-600">Off</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-indigo-500 peer-focus:ring-2 transition-all" />
            <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
          </label>
          <span className="text-sm text-gray-600">On</span>
        </div>
      </div>

      {/* Birthday Notifications */}
      <div className="flex p-4 border border-gray-200 rounded-lg shadow-md">
        <div className="block">
          <h3 className="text-lg font-semibold text-gray-700">
            Archive Button Visibility
          </h3>
          <p className="text-md text-gray-600">
            Choose whether you want to see the archive button in the sidebar.
          </p>
        </div>
        <div className="flex items-center ml-auto space-x-4">
          <span className="text-sm text-gray-600">Off</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-indigo-500 peer-focus:ring-2 transition-all" />
            <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
          </label>
          <span className="text-sm text-gray-600">On</span>
        </div>
      </div>

      {/* Account Actions Card */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg text-left font-semibold text-gray-700 mb-2">
          Account Actions
        </h3>
        <div className="flex mt-3 mb-1 mx-10 justify-between space-x-10">
          {/* Set to Default Section */}
          <div className="my-auto items-center p-3 rounded-lg border border-gray-200 max-w-[30rem]">
            <span>Set All Contacts to Default:</span>
            <button className="text-blue-500 font-medium text-sm px-2 py-1 ml-2 rounded hover:text-white hover:bg-blue-500 duration-200 ease-in-out">
              Set to Default
            </button>
            <p className="mt-1 text-sm text-gray-500 leading-relaxed">
              Clicking <span className="font-semibold">Set to default</span>{" "}
              sends a mail to your registered email address, containing a link
              through which you can change/reset your password.
            </p>
          </div>
          {/* Archive/Unarchive Section */}
          <div className="my-auto items-center p-3 rounded-lg border border-gray-200 max-w-[30rem]">
            <span>Archive/Unarchive:</span>
            <button className="text-indigo-500 text-sm font-medium px-2 py-1 ml-2 rounded hover:text-white hover:bg-indigo-500 duration-200 ease-in-out">
              Archive
            </button>
            <button className="text-indigo-500 text-sm font-medium px-2 py-1 ml-2 rounded hover:text-white hover:bg-indigo-500 duration-200 ease-in-out">
              Unarchive
            </button>
            <p className="mt-1 text-sm text-gray-500 leading-relaxed">
              Clicking <span className="font-semibold">Archive/Unarchive</span>{" "}
              will archive or unarchive all your contacts. This action may cause
              organization errors. Be cautious.
            </p>
          </div>
          {/* Delete Account Section */}
          <div className="my-auto items-center p-3 rounded-lg border border-gray-200 max-w-[30rem]">
            <span>Delete All Contacts:</span>
            <button className="text-red-500 text-sm font-medium px-2 py-1 ml-2 rounded hover:text-white hover:bg-red-500 duration-200 ease-in-out">
              Delete
            </button>
            <p className="mt-1 text-sm text-gray-500 leading-relaxed">
              Clicking <span className="font-semibold">Delete</span> will delete
              all your contacts. Keep in mind that this action is an
              irreversible action.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StarConnectSettings;
