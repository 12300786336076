import React from "react";
import { GoLinkExternal } from "react-icons/go";
import { BiMessageSquare, BiPhone } from "react-icons/bi";
import { Base64Util } from "../../utils/B64EncodeAndDecode";

function ContactCard({ contacts, currentContactAvatarPreference }) {
  return (
    <div className="h-full w-full mt-8">
      {contacts && contacts.length > 0 ? (
        <div className="grid lg:gap-x-3 md:gap-x-28 gap-y-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5">
          {contacts.map((localContact) => {
            return (
              <div
                key={localContact.id}
                className="bg-gray-100 justify-center mx-auto h-[14em] w-[13rem] rounded-xl hover:shadow-xl transition duration-300 ease-in-out"
              >
                <div
                  className={`relative mx-auto mt-4 h-28 w-28 rounded-full flex items-center justify-center ${
                    currentContactAvatarPreference === "filled-color"
                      ? localContact.avatarColor === "green"
                        ? "bg-green-500"
                        : localContact.avatarColor === "red"
                        ? "bg-red-500"
                        : localContact.avatarColor === "blue"
                        ? "bg-blue-500"
                        : localContact.avatarColor === "purple"
                        ? "bg-purple-500"
                        : localContact.avatarColor === "pink"
                        ? "bg-pink-500"
                        : "bg-gray-300"
                      : ""
                  }`}
                >
                  {/* Gradient Ring */}
                  {currentContactAvatarPreference === "gradient-ring" && (
                    <div
                      className={`absolute inset-0 rounded-full ${
                        localContact.contactEncryption === "base64" ||
                        localContact.contactEncryption === "aes256"
                          ? "bg-gradient-to-r from-yellow-200 via-purple-400 to-blue-500 p-1"
                          : "bg-gray-300 p-[0.18rem]"
                      }`}
                    >
                      <div
                        className="h-full w-full rounded-full bg-white"
                        style={{
                          backgroundColor: "transperent",
                        }}
                      >
                        <span className="text-gray-600 text-4xl font-semibold flex items-center justify-center h-full">
                          {localContact.contactEncryption === "base64"
                            ? Base64Util.decode(localContact.firstName)[0] +
                              (localContact.lastName
                                ? Base64Util.decode(localContact.lastName)[0]
                                : "")
                            : localContact.firstName[0] +
                              (localContact.lastName
                                ? localContact.lastName[0]
                                : "")}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* Filled Color */}
                  <span className="text-white text-4xl font-semibold flex items-center justify-center h-full">
                    {localContact.contactEncryption === "base64"
                      ? Base64Util.decode(localContact.firstName)[0] +
                        (localContact.lastName
                          ? Base64Util.decode(localContact.lastName)[0]
                          : "")
                      : localContact.firstName[0] +
                        (localContact.lastName ? localContact.lastName[0] : "")}
                  </span>
                </div>
                {/* Contact Name */}
                <h2 className="text-xl font-medium text-center text-gray-700 mt-3">
                  {localContact.contactEncryption === "base64"
                    ? Base64Util.decode(localContact.firstName) +
                      " " +
                      Base64Util.decode(localContact.lastName)
                    : localContact.firstName + " " + localContact.lastName}
                </h2>
                <div className="flex space-x-3 justify-center mt-3">
                  <div className="p-2 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer">
                    <BiPhone className="text-gray-600" title="Call" />
                  </div>
                  <div className="p-2 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer">
                    <BiMessageSquare
                      className="text-gray-600"
                      title="Message"
                    />
                  </div>
                  <div className="p-2 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer">
                    <GoLinkExternal
                      className="text-gray-600"
                      title="View Contact"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-gray-600 text-center mt-5">No contacts available</p>
      )}
    </div>
  );
}

export default ContactCard;
