import React from "react";
import { CiMail } from "react-icons/ci";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

function UnivAbout() {
  const emailOne = "nikitasingh121966@gmail.com";
  const emailTwo = "kabeerkhb2020@gmail.com";
  const linkedinURLOne = "https://www.linkedin.com/in/nikita-singh-88a3aa2b9/";
  const linkedinURLTwo =
    "https://www.linkedin.com/in/sohamm-kulkarni-1b418b292/";
  const instagramURL = "https://www.instagram.com/nkttaaaa/";

  // Handle email id copying
  const handleEmailCopy = (selectedEmail) => {
    navigator.clipboard.writeText(selectedEmail);
    alert("Email copied to clipboard!");
  };

  return (
    <div className="flex ml-8 space-x-12">
      {/* Social Media & Networking */}
      <div className="h-[26.5em] w-[18rem] shadow-xl rounded-lg">
        <img
          alt=""
          draggable="false"
          src="teamPic1.png"
          className="bg-gradient-to-r pt-8 pl-5  from-[#8795fe] via-[#a0c5ff] to-[#8795fe] w-[18rem] rounded-t-lg"
        />
        <div className="justify-center text-center items-center">
          <h3 className="font-doto text-[1.8rem] text-gray-700 mt-2 -mb-[0.35rem]">
            Nikita Singh
          </h3>
          <span className="text-gray-500 text-xs">
            Social Media & Networking
          </span>
        </div>

        {/* Socials */}
        <ul className="flex justify-center items-center space-x-3 mt-4">
          <li
            className="p-2 text-lg text-white bg-gray-600 rounded-lg cursor-pointer hover:bg-gray-700 transition ease-in-out duration-200"
            onClick={() => handleEmailCopy(emailOne)}
            title="Copy email"
          >
            <CiMail />
          </li>
          <li
            className="p-2 text-lg text-white bg-gray-600 rounded-lg cursor-pointer hover:bg-gray-700 transition ease-in-out duration-200"
            onClick={() => window.open(linkedinURLOne, "_blank")}
            title="View LinkedIn"
          >
            <FaLinkedin />
          </li>
          <li
            className="p-2 text-lg text-white bg-gray-600 rounded-lg cursor-pointer hover:bg-gray-700 transition ease-in-out duration-200"
            onClick={() => window.open(instagramURL, "_blank")}
            title="View Instagram"
          >
            <FaInstagram />
          </li>
        </ul>
      </div>

      {/* Founder & CEO */}
      <div className="h-[26.5rem] w-[18rem] shadow-xl rounded-lg">
        <img
          alt=""
          draggable="false"
          src="teamPic2.png"
          className="bg-gradient-to-r pt-1 from-[#8795fe] via-[#a0c5ff] to-[#8795fe] w-[18rem] rounded-t-lg"
        />
        <div className="justify-center text-center items-center">
          <h3 className="font-doto text-[1.8rem] text-gray-700 mt-2 -mb-[0.35rem]">
            Sohamm Kulkarni
          </h3>
          <span className="text-gray-500 text-xs">Founder & CEO</span>
        </div>

        {/* Socials */}
        <ul className="flex justify-center items-center space-x-3 mt-3">
          <li
            className="p-2 text-lg text-white bg-gray-600 rounded-lg cursor-pointer hover:bg-gray-700 transition ease-in-out duration-200"
            onClick={() => navigator.clipboard.writeText(emailTwo)}
            title="Copy email"
          >
            <CiMail />
          </li>
          <li
            className="p-2 text-lg text-white bg-gray-600 rounded-lg cursor-pointer hover:bg-gray-700 transition ease-in-out duration-200"
            onClick={() => window.open(linkedinURLTwo, "_blank")}
            title="View LinkedIn"
          >
            <FaLinkedin />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default UnivAbout;
