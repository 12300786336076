import {
  FaStar,
  FaHashtag,
  FaChevronRight,
  FaRegUserCircle,
} from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdCheck, MdLabelOutline, MdOutlineArchive } from "react-icons/md";

function AltSidebar({
  labelInput,
  editLabelId,
  labels = [],
  setLabelInput,
  handleAddLabel,
  editLabelValue,
  setEditLabelId,
  setShowArchived,
  handleEditLabel,
  handleDeleteLabel,
  setEditLabelValue,
  handleNavigateHome,
}) {
  let navigate = useNavigate();
  const modalRef = useRef(null);
  const sortModalRef = useRef(null);
  const sortButtonRef = useRef(null);
  const labelButtonRef = useRef(null);
  const [labelText, setLabelText] = useState("");
  const [editingLabel, setEditingLabel] = useState(null);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);

  // Handle noteModal modal escape/close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsLabelModalOpen(false);
      }
    };
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setIsLabelModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Handle click outside of the modal
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isSortModalOpen &&
        sortModalRef.current &&
        !sortModalRef.current.contains(event.target) &&
        !sortButtonRef.current.contains(event.target)
      ) {
        setIsSortModalOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isSortModalOpen]);

  // Handle archive button click
  const handleArchiveClick = () => {
    setShowArchived(true);
    navigate("/starconnect/archive");
  };

  // Handle the clicking of cancel
  const handleCancelEdit = () => {
    setEditingLabel(null);
    setLabelText("");
    setEditLabelId(null);
    setEditLabelValue("");
  };

  // Save edited changes
  const handleSaveEdit = () => {
    if (editLabelValue.trim() !== "") {
      handleEditLabel(editLabelId, labelText);
    }
    setEditingLabel(null);
    setLabelText("");
    setEditLabelId(null);
    setEditLabelValue("");
  };

  return (
    <div className="w-72 h-[calc(100vh-1.8rem)] bg-gray-100 border-r border-gray-200 rounded-tl-lg rounded-bl-lg relative">
      <h1
        className="text-xl font-bold text-gray-800 mt-4 ml-4 cursor-pointer"
        onClick={handleNavigateHome}
      >
        Star Connect
      </h1>
      <div className="w-72 border-t border-gray-300 mt-2" />
      <div className="flex flex-col space-y-3 px-4 py-2">
        {/* Top-left menu */}
        <div className="justify-between relative">
          <>
            <ul>
              <li
                className="flex items-center text-gray-800 hover:bg-gray-200 p-2 rounded-lg cursor-pointer transition duration-200"
                onClick={() => navigate("/starconnect/contacts")}
              >
                <FaRegUserCircle size={20} className="mr-2" />
                <span className="text-md font-medium">All Contacts</span>
              </li>

              <li
                className="flex items-center text-gray-800 hover:bg-gray-200 p-2 rounded-lg cursor-pointer transition duration-200"
                onClick={handleArchiveClick}
              >
                <MdOutlineArchive size={20} className="mr-2" />
                <span className="text-md font-medium">Archive</span>
              </li>
            </ul>
            <div className="w-72 -ml-4 border-t border-gray-300 my-2" />
            <li
              className="flex items-center px-2 py-2 mb-1 bg-gray-200 text-gray-700 rounded-md duration-200 ease-in-out"
              onClick={() => setIsLabelModalOpen(true)}
              ref={labelButtonRef}
            >
              <MdLabelOutline size={20} className="mr-2" />
              <span className="text-md font-medium">Labels</span>
              <button
                className="flex items-center text-sm font-medium text-blue-600 hover:text-blue-800 ml-auto"
                onClick={() => setIsLabelModalOpen(true)}
              >
                Add Labels
              </button>
            </li>

            <Link to="/starnote/labels/starred">
              <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 ml-3 rounded cursor-pointer transition duration-200">
                <div className="flex items-center">
                  <FaStar className="text-sm text-yellow-300 mr-2" size={15} />
                  <span className="text-md font-medium">Starred</span>
                </div>
                <FaChevronRight className="text-sm mr-2" />
              </li>
            </Link>
            {labels.map((label, index) => (
              <Link to={`/starnote/labels/${label.labelName}`} key={index}>
                <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 ml-3 rounded cursor-pointer transition duration-200">
                  <div className="flex items-center">
                    <FaHashtag className="text-sm mr-2" size={15} />
                    <span className="text-md font-medium">
                      {label.labelName || "Untitled"}
                    </span>
                  </div>
                  <FaChevronRight className="text-sm mr-2" />
                </li>
              </Link>
            ))}

            {/* Contact Sorting Modal */}
            {isSortModalOpen && (
              <div
                className="absolute bg-white shadow-lg shadow-gray-300 rounded-md py-3 pl-2 pr-4 z-50 border border-gray-300 overflow-y-auto custom-scrollbar max-h-48"
                style={{
                  top:
                    labelButtonRef.current.offsetTop +
                    labelButtonRef.current.offsetHeight -
                    44,
                  left: labelButtonRef.current.offsetLeft + 150,
                }}
                ref={sortModalRef}
              >
                <div className="absolute left-[-7px] top-4 h-0 w-0 border-t-8 border-t-transparent border-b-8 border-b-transparent border-r-8 border-r-white" />
                <span className="w-full font-semibold ml-3 text-gray-700 -mt-1">
                  Labels
                </span>
                <div className="border-t border-gray-300 mt-2 -ml-2 -mr-4" />
                {labels.map((label) => (
                  <div
                    key={label.labelName}
                    className="flex items-center text-gray-800 mt-3 cursor-pointer"
                  >
                    <MdLabelOutline size={19} className="mr-1" />

                    <>
                      <span className="text-sm font-medium mt-[0.15rem]">
                        {label.labelName.length > 5
                          ? `${label.labelName.slice(0, 5)}...`
                          : label.labelName}
                      </span>
                    </>
                  </div>
                ))}
              </div>
            )}

            {/* Label Modal */}
            {isLabelModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                <div
                  className="relative bg-white rounded-lg shadow-md p-4 space-y-3"
                  ref={modalRef}
                >
                  <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold text-gray-800">
                      Labels
                    </h2>
                    <FiX
                      className="text-lg text-gray-700 cursor-pointer"
                      onClick={() => setIsLabelModalOpen(false)}
                      title="Close"
                    />
                  </div>
                  <div className="border-t border-gray-300 mt-2 -mx-4" />
                  <div className="flex items-center mt-2">
                    <input
                      type="text"
                      placeholder="Add a label"
                      className="w-48 border rounded-md p-1"
                      value={labelInput}
                      onChange={(e) => setLabelInput(e.target.value)}
                    />
                    <button
                      className="text-green-500 ml-2"
                      onClick={() => handleAddLabel(labelInput)}
                    >
                      <MdCheck size={20} />
                    </button>
                  </div>
                  <div
                    className={`flex flex-col ${
                      labels.length > 5
                        ? "max-h-40 overflow-y-auto custom-scrollbar"
                        : ""
                    }`}
                  >
                    {labels.map((label, index) => (
                      <div
                        key={label.labelName}
                        className="flex items-center mt-3"
                      >
                        <MdLabelOutline size={20} className="mr-1" />
                        {editingLabel === label.labelName ? (
                          <>
                            <input
                              type="text"
                              value={labelText}
                              onChange={(e) => setLabelText(e.target.value)}
                              className="w-24 border rounded-md p-1 mr-2"
                            />
                            <div className="ml-auto flex space-x-2">
                              <button
                                className="text-green-500"
                                onClick={handleSaveEdit}
                              >
                                <MdCheck size={20} />
                              </button>
                              <button
                                className="text-gray-500 hover:text-red-500"
                                onClick={handleCancelEdit}
                              >
                                <FiX size={20} />
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <span className="font-medium">
                              {label.labelName}
                            </span>
                            <div className="ml-auto flex space-x-2">
                              <button
                                className="text-gray-500 hover:text-gray-700"
                                onClick={() => {
                                  setEditLabelId(index);
                                  setEditLabelValue(label.labelName);
                                  setLabelText(label.labelName);
                                  setEditingLabel(label.labelName);
                                }}
                              >
                                <AiOutlineEdit size={18} />
                              </button>
                              <button
                                className="text-gray-500 hover:text-red-500"
                                onClick={() => handleDeleteLabel(index)}
                              >
                                <AiOutlineDelete size={18} />
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export default AltSidebar;
