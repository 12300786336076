import { db } from "../firebase";
import { AuthContext } from "./AuthContext";
import { Base64Util } from "../utils/B64EncodeAndDecode";
import { createContext, useState, useEffect, useContext } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chats, setChats] = useState([]);
  const [usernames, setUsernames] = useState({});
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const getChats = async () => {
      try {
        const chatData = [];
        // Query chats where currentUser is participant1 or participant2
        const queries = [
          query(
            collection(db, "chats"),
            where("participant1", "==", currentUser.email)
          ),
          query(
            collection(db, "chats"),
            where("participant2", "==", currentUser.email)
          ),
        ];

        const chatSnapshots = await Promise.all(queries.map(getDocs));
        chatSnapshots.forEach((snapshot) => {
          snapshot.forEach((doc) => {
            chatData.push({ ...doc.data(), id: doc.id });
          });
        });

        setChats(chatData);

        // Fetch usernames for other participants
        // Attempt both, plain and encoded, email queries
        const usernamesData = {};
        await Promise.all(
          chatData.map(async (chat) => {
            // Determine the "other" participant
            const otherParticipant =
              chat.participant1 === currentUser.email
                ? chat.participant2
                : chat.participant1;

            // If username is not already in the usernamesData object, fetch it
            if (!usernamesData[otherParticipant]) {
              const plainEmail = otherParticipant;
              const encodedEmail = Base64Util.encode(otherParticipant);

              // Prepare both queries.
              const contactsCollection = collection(
                db,
                `contacts-${currentUser.email}`
              );
              const queryPlain = query(
                contactsCollection,
                where("email", "==", plainEmail)
              );
              const queryEncoded = query(
                contactsCollection,
                where("email", "==", encodedEmail)
              );

              // Execute both queries in parallel
              const [snapshotPlain, snapshotEncoded] = await Promise.all([
                getDocs(queryPlain),
                getDocs(queryEncoded),
              ]);

              // Use whichever query returns a document
              let foundDoc = null;
              if (!snapshotPlain.empty) {
                snapshotPlain.forEach((doc) => {
                  if (!foundDoc) foundDoc = doc;
                });
              } else if (!snapshotEncoded.empty) {
                snapshotEncoded.forEach((doc) => {
                  if (!foundDoc) foundDoc = doc;
                });
              }

              // If a document was found, extract and decode the username
              if (foundDoc) {
                const userData = foundDoc.data();
                const encryptionType = userData.contactEncryption;
                const firstName = userData.firstName
                  ? encryptionType === "base64"
                    ? Base64Util.decode(userData.firstName)
                    : userData.firstName
                  : "";
                const lastName = userData.lastName
                  ? encryptionType === "base64"
                    ? Base64Util.decode(userData.lastName)
                    : userData.lastName
                  : "";
                const username = lastName
                  ? `${firstName} ${lastName}`
                  : firstName;
                // Use the plain email as the key
                usernamesData[otherParticipant] = username;
              }
            }
          })
        );

        setUsernames(usernamesData);
      } catch (err) {
        console.error("Error fetching documents: ", err);
      }
    };

    if (currentUser?.email) {
      getChats();
    }
  }, [currentUser]);

  return (
    <ChatContext.Provider value={{ chats, usernames }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
