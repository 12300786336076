import { db } from "../../firebase";
import { Link } from "react-router-dom";
import { IoLogoGithub } from "react-icons/io5";
import { doc, updateDoc } from "firebase/firestore";
import { FiArrowRight, FiEdit } from "react-icons/fi";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { FaLinkedin, FaSnapchatGhost } from "react-icons/fa";
import { FaInstagram, FaThreads, FaXTwitter } from "react-icons/fa6";
import { AiOutlineArrowRight, AiOutlineCheck } from "react-icons/ai";

function SocialBanners({ content, docId }) {
  const [error, setError] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [inputValues, setInputValues] = useState({
    instagram: "",
    snapchat: "",
    threads: "",
    x: "",
    github: "",
    linkedin: "",
  });
  const [activeInput, setActiveInput] = useState("");

  // Auto-dismiss error messages after 5s
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  // Handle changes to input fields
  const handleInputChange = (e) => {
    setInputValues({
      ...inputValues,
      [activeInput]: e.target.value,
    });
  };

  // Save links with validation
  const saveSocialLink = async (e) => {
    e.preventDefault();
    const collectionTitle = `contacts-${currentUser.email}`;
    const input = inputValues[activeInput].trim().toLowerCase();

    // Input sanitization
    const containsSuspiciousPatterns =
      /['";=<>()]|(%27)|(%22)|(%3D)|(%3C)|(%3E)/i.test(input);
    if (containsSuspiciousPatterns) {
      setError("Invalid characters detected");
      return;
    }

    // Define validation rules
    const validationRules = {
      // Instagram
      instagram: {
        patterns: ["https://www.instagram.com/", "instagram.com/"],
        forbiddenPatterns: [],
      },
      // Snapchat
      snapchat: {
        patterns: [],
        forbiddenPatterns: ["https://", "http://"],
      },
      // LinkedIn
      linkedin: {
        patterns: ["https://www.linkedin.com/", "linkedin.com/"],
        forbiddenPatterns: [],
      },
      // Threads
      threads: {
        patterns: ["https://www.threads.net/@", "threads.net/@"],
        forbiddenPatterns: [],
      },
      // X (twitter)
      x: {
        patterns: ["https://x.com/", "x.com/"],
        forbiddenPatterns: [],
      },
      // GitHub
      github: {
        patterns: ["https://github.com/", "github.com/"],
        forbiddenPatterns: [],
      },
    };

    // Validate input against defined rules
    const rule = validationRules[activeInput];
    if (!rule) {
      setError("Invalid platform");
      return;
    }

    // Check for forbidden patterns
    if (rule.forbiddenPatterns.some((pattern) => input.startsWith(pattern))) {
      setError("Invalid URL format");
      return;
    }

    // Ensure URL matches required platform format
    if (
      rule.patterns.length > 0 &&
      !rule.patterns.some((pattern) => input.startsWith(pattern))
    ) {
      setError("Invalid Link Format");
      return;
    }

    // Update links in database
    try {
      const docRef = doc(db, collectionTitle, docId);
      await updateDoc(docRef, {
        [`socials.${activeInput}`]: input,
      });
      setActiveInput("");
    } catch (error) {
      console.error("Error updating social link:", error);
      setError("Failed to save");
    }
  };

  return (
    <div className="flex flex-col items-center mt-4 space-y-4">
      {/* Instagram Banner */}
      {activeInput === "instagram" ? (
        <div className="flex items-center w-[calc(100%-6rem)]">
          <div className="p-3 text-2xl mr-2 rounded-xl bg-gradient-to-r from-yellow-400 via-pink-500 to-purple-500">
            <FaInstagram className="text-white" />
          </div>
          <div className="relative flex-1">
            <input
              type="text"
              className="w-full text-center p-2 pr-6 text-sm border rounded-lg border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-transparent placeholder-gray-500 outline-none transition-all duration-200"
              placeholder="Enter profile URL"
              value={inputValues.instagram}
              onChange={handleInputChange}
            />
            <AiOutlineCheck
              className="absolute top-1/2 right-2 transform -translate-y-1/2 text-xs text-purple-400 cursor-pointer"
              title="Save"
              onClick={saveSocialLink}
            />
          </div>
        </div>
      ) : (
        <button
          className="flex justify-center items-center w-[calc(100%-6rem)] bg-gradient-to-r from-yellow-400 via-pink-500 to-purple-500 p-2 rounded-lg group overflow-hidden relative"
          onClick={() => {
            if (content?.instagram) {
              const url = content.instagram.startsWith("http")
                ? content.instagram
                : `https://${content.instagram}`;
              window.open(url, "_blank", "noopener,noreferrer");
            } else {
              setActiveInput("instagram");
            }
          }}
        >
          <FaInstagram className="mr-2 text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300" />
          <span className="text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300">
            Instagram
          </span>
          {content?.instagram ? (
            <span className="absolute right-0 p-[0.4rem] text-sm bg-gray-50 rounded-full opacity-0 group-hover:opacity-50 group-hover:right-2 transition-all duration-300">
              <FiEdit
                className="text-gray-900 opacity-90"
                title="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveInput("instagram");
                }}
              />
            </span>
          ) : (
            <span className="absolute right-0 opacity-0 group-hover:opacity-100 group-hover:right-2 transition-all duration-300 text-white">
              <FiArrowRight />
            </span>
          )}
        </button>
      )}

      {/* Snapchat Banner */}
      {activeInput === "snapchat" ? (
        <div className="flex items-center w-[calc(100%-6rem)]">
          <div className="p-3 text-2xl mr-2 rounded-xl bg-yellow-400">
            <FaSnapchatGhost className="text-white" />
          </div>
          <div className="relative flex-1">
            <input
              type="text"
              className="w-full text-center p-2 pr-6 text-sm border rounded-lg border-gray-300 focus:ring-2 focus:ring-yellow-300 focus:border-transparent placeholder-gray-500 outline-none transition-all duration-200"
              placeholder="Enter Snapchat username"
              value={inputValues.snapchat}
              onChange={handleInputChange}
            />
            <AiOutlineCheck
              className="absolute top-1/2 right-2 transform -translate-y-1/2 text-xs text-yellow-300 cursor-pointer"
              title="Save"
              onClick={saveSocialLink}
            />
          </div>
        </div>
      ) : (
        <button
          className="flex justify-center items-center w-[calc(100%-6rem)] bg-yellow-400 p-2 rounded-lg group overflow-hidden relative"
          onClick={() => {
            if (content?.snapchat) {
              navigator.clipboard.writeText(content?.snapchat);
              alert("Copied to clipboard!");
            } else {
              setActiveInput("snapchat");
            }
          }}
        >
          <FaSnapchatGhost className="mr-2 text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300" />
          <span className="text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300">
            Snapchat
          </span>
          {content?.snapchat ? (
            <span className="absolute right-0 p-[0.4rem] text-sm bg-gray-50 rounded-full opacity-0 group-hover:opacity-50 group-hover:right-2 transition-all duration-300">
              <FiEdit
                className="text-gray-900 opacity-100"
                title="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveInput("snapchat");
                }}
              />
            </span>
          ) : (
            <span className="absolute right-0 opacity-0 group-hover:opacity-100 group-hover:right-2 transition-all duration-300 text-white">
              <FiArrowRight />
            </span>
          )}
        </button>
      )}

      {/* Linkedin */}
      {activeInput === "linkedin" ? (
        <div className="flex items-center w-[calc(100%-6rem)]">
          <div className="p-3 text-2xl mr-2 rounded-xl bg-blue-500">
            <FaLinkedin className="text-white" />
          </div>
          <div className="relative flex-1">
            <input
              type="text"
              className="w-full text-center p-2 pr-6 text-sm border rounded-lg border-gray-300 focus:ring-2 focus:ring-blue-400 focus:border-transparent placeholder-gray-500 outline-none transition-all duration-200"
              placeholder="Enter profile URL"
              value={inputValues.linkedin}
              onChange={handleInputChange}
            />
            <AiOutlineCheck
              className="absolute top-1/2 right-2 transform -translate-y-1/2 text-xs text-blue-400 cursor-pointer"
              title="Save"
              onClick={saveSocialLink}
            />
          </div>
        </div>
      ) : (
        <button
          className="flex justify-center items-center w-[calc(100%-6rem)] bg-blue-500 p-2 rounded-lg group overflow-hidden relative"
          onClick={() => {
            if (content?.linkedin) {
              const url = content.linkedin.startsWith("http")
                ? content.linkedin
                : `https://${content.linkedin}`;
              window.open(url, "_blank", "noopener,noreferrer");
            } else {
              setActiveInput("linkedin");
            }
          }}
        >
          <FaLinkedin className="mr-2 text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300" />
          <span className="text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300">
            LinkedIn
          </span>
          {content?.linkedin ? (
            <span className="absolute right-0 p-[0.4rem] text-sm bg-gray-50 rounded-full opacity-0 group-hover:opacity-50 group-hover:right-2 transition-all duration-300">
              <FiEdit
                className="text-gray-900 opacity-100"
                title="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveInput("linkedin");
                }}
              />
            </span>
          ) : (
            <span className="absolute right-0 opacity-0 group-hover:opacity-100 group-hover:right-2 transition-all duration-300 text-white">
              <FiArrowRight />
            </span>
          )}
        </button>
      )}

      {/* Threads Banner */}
      {activeInput === "threads" ? (
        <div className="flex items-center w-[calc(100%-6rem)]">
          <div className="p-3 text-2xl mr-2 rounded-xl bg-black">
            <FaThreads className="text-white" />
          </div>
          <div className="relative flex-1">
            <input
              type="text"
              className="w-full text-center p-2 pr-6 text-sm border rounded-lg border-gray-300 focus:ring-2 focus:ring-gray-500 focus:border-transparent placeholder-gray-500 outline-none transition-all duration-200"
              placeholder="Enter profile URL"
              value={inputValues.threads}
              onChange={handleInputChange}
            />
            <AiOutlineCheck
              className="absolute top-1/2 right-2 transform -translate-y-1/2 text-xs text-gray-400 cursor-pointer"
              title="Save"
              onClick={saveSocialLink}
            />
          </div>
        </div>
      ) : (
        <button
          className="flex justify-center items-center w-[calc(100%-6rem)] bg-black p-2 rounded-lg group overflow-hidden relative"
          onClick={() => {
            if (content?.threads) {
              const url = content.threads.startsWith("http")
                ? content.threads
                : `https://${content.threads}`;
              window.open(url, "_blank", "noopener,noreferrer");
            } else {
              setActiveInput("threads");
            }
          }}
        >
          <FaThreads className="mr-2 text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300" />
          <span className="text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300">
            Threads
          </span>
          {content?.threads ? (
            <span className="absolute right-0 p-[0.4rem] text-sm bg-gray-50 rounded-full opacity-0 group-hover:opacity-50 group-hover:right-2 transition-all duration-300">
              <FiEdit
                className="text-gray-900 opacity-100"
                title="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveInput("threads");
                }}
              />
            </span>
          ) : (
            <span className="absolute right-0 opacity-0 group-hover:opacity-100 group-hover:right-2 transition-all duration-300 text-white">
              <FiArrowRight />
            </span>
          )}
        </button>
      )}

      {/* X (Twitter) Banner */}
      {activeInput === "x" ? (
        <div className="flex items-center w-[calc(100%-6rem)]">
          <div className="p-3 text-2xl  mr-2 rounded-xl bg-black">
            <FaXTwitter className="text-white" />
          </div>
          <div className="relative flex-1">
            <input
              type="text"
              className="w-full text-center p-2 pr-6 text-sm border rounded-lg border-gray-300 focus:ring-2 focus:ring-gray-500 focus:border-transparent placeholder-gray-500 outline-none transition-all duration-200"
              placeholder="Enter profile URL"
              value={inputValues.x}
              onChange={handleInputChange}
            />
            <AiOutlineCheck
              className="absolute top-1/2 right-2 transform -translate-y-1/2 text-xs text-gray-400 cursor-pointer"
              title="Save"
              onClick={saveSocialLink}
            />
          </div>
        </div>
      ) : (
        <button
          className="flex justify-center items-center w-[calc(100%-6rem)] bg-black p-2 rounded-lg group overflow-hidden relative"
          onClick={() => {
            if (content?.x) {
              const url = content.x.startsWith("http")
                ? content.x
                : `https://${content.x}`;
              window.open(url, "_blank", "noopener,noreferrer");
            } else {
              setActiveInput("x");
            }
          }}
        >
          <FaXTwitter className="mr-2 text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300" />
          <span className="text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300">
            X (<i>Twitter</i>)
          </span>
          {content?.x ? (
            <span className="absolute right-0 p-[0.4rem] text-sm bg-gray-50 rounded-full opacity-0 group-hover:opacity-50 group-hover:right-2 transition-all duration-300">
              <FiEdit
                className="text-gray-900 opacity-100"
                title="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveInput("x");
                }}
              />
            </span>
          ) : (
            <span className="absolute right-0 opacity-0 group-hover:opacity-100 group-hover:right-2 transition-all duration-300 text-white">
              <FiArrowRight />
            </span>
          )}
        </button>
      )}

      {/* Github Banner */}
      {activeInput === "github" ? (
        <div className="flex items-center w-[calc(100%-6rem)]">
          <div className="p-3 text-2xl  mr-2 rounded-xl bg-black">
            <IoLogoGithub className="text-white" />
          </div>
          <div className="relative flex-1">
            <input
              type="text"
              className="w-full text-center p-2 pr-6 text-sm border rounded-lg border-gray-300 focus:ring-2 focus:ring-gray-500 focus:border-transparent placeholder-gray-500 outline-none transition-all duration-200"
              placeholder="Enter profile URL"
              value={inputValues.github}
              onChange={handleInputChange}
            />
            <AiOutlineCheck
              className="absolute top-1/2 right-2 transform -translate-y-1/2 text-xs text-gray-400 cursor-pointer"
              title="Save"
              onClick={saveSocialLink}
            />
          </div>
        </div>
      ) : (
        <button
          className="flex justify-center items-center w-[calc(100%-6rem)] bg-black p-2 rounded-lg group overflow-hidden relative"
          onClick={() => {
            if (content?.github) {
              const url = content.github.startsWith("http")
                ? content.github
                : `https://${content.github}`;
              window.open(url, "_blank", "noopener,noreferrer");
            } else {
              setActiveInput("github");
            }
          }}
        >
          <IoLogoGithub className="mr-2 text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300" />
          <span className="text-white group-hover:translate-x-[-0.5rem] transition-transform duration-300">
            GitHub
          </span>
          {content?.github ? (
            <span className="absolute right-0 p-[0.4rem] text-sm bg-gray-50 rounded-full opacity-0 group-hover:opacity-50 group-hover:right-2 transition-all duration-300">
              <FiEdit
                className="text-gray-900 opacity-100"
                title="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveInput("github");
                }}
              />
            </span>
          ) : (
            <span className="absolute right-0 opacity-0 group-hover:opacity-100 group-hover:right-2 transition-all duration-300 text-white">
              <FiArrowRight />
            </span>
          )}
        </button>
      )}
      {error && (
        <span className="bg-red-100 text-sm text-red-500 p-2 rounded-lg">
          {error}
        </span>
      )}
      <Link
        to="/documentation/starchat"
        className="flex items-center justify-center text-blue-600 text-sm hover:underline space-x-1"
      >
        <span>Learn more</span>
        <AiOutlineArrowRight className="text-xs text-blue-600" />
      </Link>
    </div>
  );
}

export default SocialBanners;
