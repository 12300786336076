import {
  doc,
  query,
  where,
  updateDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import { FiX } from "react-icons/fi";
import { MdOutlineAutoDelete } from "react-icons/md";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";

function StarNoteSettings({ isNavOpen }) {
  const modalRef = useRef();
  const [docId, setDocId] = useState();
  const { currentUser } = useContext(AuthContext);
  const [pendingState, setPendingState] = useState("");
  const [noteAutoDeleteConfirmationModal, setNoteAutoDeleteConfirmationModal] =
    useState(false);
  const [
    currentPermanentNoteDeletionSetting,
    setCurrentPermanentNoteDeletionSetting,
  ] = useState("");

  // Handle clicks outside chat deletion modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setNoteAutoDeleteConfirmationModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setNoteAutoDeleteConfirmationModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Get user info and set docId
  useEffect(() => {
    let unsubscribeUserDoc = null;

    // Retrieve the current value of the days until deletion setting
    const getDaysValue = () => {
      if (currentUser) {
        // Days Value Query
        const dvq = query(
          collection(db, "users"),
          where("email", "==", currentUser.email)
        );
        unsubscribeUserDoc = onSnapshot(dvq, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const daysValue = doc.data().deleteNotesAfterDays;
            setDocId(doc.id);
            setCurrentPermanentNoteDeletionSetting(daysValue ?? "30"); // Default to "30" if null/undefined
          });
        });
      }
    };

    getDaysValue();

    // Cleanup function to unsubscribe the real-time listener
    return () => {
      if (unsubscribeUserDoc) unsubscribeUserDoc();
    };
  }, [currentUser, docId]);

  // Handle changes in avatar preferences
  const permanentNoteDeletionSettingChange = async (newState) => {
    try {
      if (!docId) {
        return;
      }

      const docRef = doc(db, "users", docId);
      await updateDoc(docRef, { deleteNotesAfterDays: newState || "30" });

      setCurrentPermanentNoteDeletionSetting(newState);
      setPendingState(newState);
    } catch (error) {
      alert("An error occurred while updating your preferences.");
    }
  };

  // Handle toggle action for avatar preference
  const handlePermanentNoteDeletionToggle = (checked) => {
    setPendingState(checked ? "60" : "30");
    setNoteAutoDeleteConfirmationModal(true);
  };

  // Confirm the avatar preference toggle action and update db
  const confirmPermanentNoteDeletionToggle = async () => {
    if (!docId) {
      return;
    }
    await permanentNoteDeletionSettingChange(pendingState);
    setNoteAutoDeleteConfirmationModal(false);
  };

  return (
    <div
      className={`pl-2 pr-5 pb-4 -mr-6 -mt-5 space-y-6 overflow-y-auto custom-scrollbar ${
        isNavOpen ? "w-[calc(100vw-35rem)]" : "w-[calc(100vw-22rem)]"
      } h-[calc(100vh-2.5rem)]`}
    >
      <h2 className="my-4 text-xl font-medium">Star Note</h2>

      {/* Note permanent deletion toggle */}
      <div className="flex p-4 border border-gray-200 rounded-lg shadow-md">
        <div className="block">
          <h3 className="text-lg font-semibold text-gray-700">
            Days Until Deletion
          </h3>
          <p className="text-md text-gray-600">
            After you bin notes, how long do you want them to stay before
            getting deleted permanently? Select from either 30 days or 60 days.
          </p>
        </div>
        <div className="flex items-center ml-auto space-x-4">
          <span className="text-sm text-gray-600">30 days</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={currentPermanentNoteDeletionSetting === "60"}
              onChange={(e) =>
                handlePermanentNoteDeletionToggle(e.target.checked)
              }
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-indigo-500 peer-focus:ring-2 transition-all" />
            <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
          </label>
          <span className="text-sm text-gray-600">60 days</span>
        </div>
      </div>

      {/* Permanent note deletion preference confirmation modal */}
      {noteAutoDeleteConfirmationModal && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[25%]"
          >
            <div className="flex justify-between items-center text-lg mb-3 px-1">
              <h2 className="font-semibold text-gray-800 ">
                Update Note Deletion Setting
              </h2>
              <FiX
                className="text-gray-700 cursor-pointer"
                onClick={() => setNoteAutoDeleteConfirmationModal(false)}
                title="Close"
              />
            </div>{" "}
            <div className="-mx-4 mb-4 border bg-white" />
            <div className="text-center">
              <p className="text-gray-600 mb-2">
                Are you sure you want to change the days until deletion setting?
              </p>
              <button
                className="flex text-md text-gray-600 hover:text-white hover:bg-gradient-to-br from-red-300 via-red-400 to-red-300 mx-auto font-medium p-2 rounded ease-in-out duration-200"
                onClick={confirmPermanentNoteDeletionToggle}
              >
                <MdOutlineAutoDelete className="my-auto mr-1" />
                Auto-delete Notes After&nbsp;
                <span className="font-semibold">
                  {pendingState === "30" ? "30" : "60"} Days
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StarNoteSettings;
