import {
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import { FiX } from "react-icons/fi";
import Note from "../components/Note";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlineUndo } from "react-icons/ai";

function StarNoteBin() {
  const deleteModalRef = useRef(null);
  const restoreModalRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const [binnedNotes, setBinnedNotes] = useState();
  const collectionTitle = `notes-${currentUser.email}`;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [permanentDeletionSetting, setPermanentDeletionSetting] = useState("");

  // Retrieve binned notes and set daysValue for note deletion
  useEffect(() => {
    let unsubscribeUserDoc = null;
    // Fetch all notes saved by the user where noteStatus is 'binned'
    const getBinnedNotes = async () => {
      if (currentUser) {
        const dnq = query(
          collection(db, collectionTitle),
          where("noteStatus", "==", "binned")
        );
        const querySnapshot = await getDocs(dnq);
        setBinnedNotes(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    };

    // Retrieve the value for the number of days before deletion
    const getDaysValue = () => {
      if (currentUser) {
        const ndq = query(
          collection(db, "users"),
          where("email", "==", currentUser.email)
        );

        unsubscribeUserDoc = onSnapshot(ndq, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const daysValue = doc.data().deleteNotesAfterDays;
            setPermanentDeletionSetting(daysValue ?? "30"); // Default to "30" if null/undefined
          });
        });
      }
    };

    getDaysValue();
    getBinnedNotes();

    // Cleanup function to unsubscribe the real-time listener
    return () => {
      if (unsubscribeUserDoc) unsubscribeUserDoc();
    };
  }, [collectionTitle, currentUser, permanentDeletionSetting]);

  // Auto delete notes whenever binnedNotes or permanentDeletionSetting updates
  useEffect(() => {
    const autoDeleteNotes = async () => {
      if (!currentUser || !binnedNotes?.length) return;

      try {
        // Parse permanent deletion setting as an integer
        const deletionThresholdDays = parseInt(permanentDeletionSetting, 10);
        if (![30, 60].includes(deletionThresholdDays)) {
          return alert("An error occurred while auto-deleting binned notes.");
        }

        // Calculate the timestamp threshold
        const thresholdDate = new Date();
        thresholdDate.setDate(thresholdDate.getDate() - deletionThresholdDays);

        // Filter and delete expired notes
        const batchDeletes = binnedNotes.map(async (note) => {
          if (note.binnedAt?.toDate() < thresholdDate) {
            await deleteDoc(doc(db, collectionTitle, note.id));
          }
        });

        // Execute deletions in parallel
        await Promise.all(batchDeletes);
      } catch (error) {
        alert("An error occurred while auto-deleting binned notes.");
      }
    };

    autoDeleteNotes();
  }, [binnedNotes, permanentDeletionSetting, currentUser, collectionTitle]);

  // Handle clicks outside chat deletion modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        deleteModalRef.current &&
        !deleteModalRef.current.contains(event.target)
      ) {
        setShowDeleteModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowDeleteModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Handle clicks outside chat deletion modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        restoreModalRef.current &&
        !restoreModalRef.current.contains(event.target)
      ) {
        setShowRestoreModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowRestoreModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Function to handle the restoring of binned notes
  const handleNoteRestore = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, {
      noteStatus: "active",
      binnedAt: null,
    });

    setBinnedNotes((prevNotes) =>
      prevNotes.filter((note) => note.id !== noteId)
    );
  };

  // Function to handle permanent deletion of notes
  const handlePermanentDelete = async (noteId) => {
    await deleteDoc(doc(db, collectionTitle, noteId));
    setBinnedNotes((prevNotes) =>
      prevNotes.filter((note) => note.id !== noteId)
    );
    alert("Note deleted successfully!");
  };

  // Function to handle action 'DELETE ALL'
  const handleDeleteAll = async () => {
    if (!binnedNotes || binnedNotes.length === 0) {
      alert("No notes available to delete.");
      setShowDeleteModal(false);
      return;
    }
    try {
      binnedNotes.forEach(async (note) => {
        await deleteDoc(doc(db, collectionTitle, note.id));
      });

      setBinnedNotes([]);
      alert("All binned notes have been deleted permanently.");
    } catch (error) {
      console.error("Error deleting all notes: ", error);
    }
    setShowDeleteModal(false);
  };

  // Function to handle action 'RESTORE ALL'
  const handleRestoreAll = async () => {
    if (!binnedNotes || binnedNotes.length === 0) {
      alert("No notes available to be restored.");
      setShowRestoreModal(false);
      return;
    }
    try {
      binnedNotes.forEach(async (note) => {
        await updateDoc(doc(db, collectionTitle, note.id), {
          noteStatus: "active",
        });
      });

      setBinnedNotes([]);
      alert("All deleted notes have been restored.");
    } catch (error) {
      console.error("Error restoring all notes: ", error);
    }
    setShowRestoreModal(false);
  };

  return (
    <div className="relative flex-1 px-4 pt-3 bg-white">
      {/* Topbar */}
      <div className="flex justify-between items-center mt-1 min-w-[calc(100vw-41rem)]">
        <h2 className="text-base sm:text-base sm:text-center md:text-xl ml-2 font-medium w-full md:w-auto">
          Binned Notes
        </h2>
        {/* Actions */}
        <ul className="flex items-center space-x-4">
          <li
            className="flex text-gray-700 p-2 list-none font-medium cursor-pointer rounded-lg border border-gray-200 shadow shadow-gray-200 hover:bg-red-500 hover:text-white duration-200 ease-in-out"
            onClick={() => setShowDeleteModal(true)}
          >
            <AiOutlineDelete className="mt-1 mr-1" />
            Delete all
          </li>
          <li
            className="flex text-gray-700 p-2 list-none font-medium cursor-pointer rounded-lg border border-gray-200 shadow shadow-gray-200 hover:bg-gray-400 hover:text-white duration-200 ease-in-out"
            onClick={() => setShowRestoreModal(true)}
          >
            <AiOutlineUndo className="mt-1 mr-1" />
            Restore all
          </li>
        </ul>
      </div>

      {/* Delete notes permanently modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={deleteModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Delete Notes
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowDeleteModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <div className="items-center justify-center">
              <p className="text-gray-600 mb-2 text-center">
                Doing this will
                <span className="font-semibold"> permanently delete</span> all
                the notes. Are you sure you want to do this?
              </p>
              <button
                className="flex text-md font-medium text-red-500 p-2 mx-auto w-full md:w-auto rounded hover:bg-red-500 hover:text-white ease-in-out duration-200"
                onClick={() => handleDeleteAll()}
              >
                <AiOutlineDelete className="mt-[0.24rem] mr-1" />
                Delete all notes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Restore notes modal */}
      {showRestoreModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={restoreModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Restore Notes
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowRestoreModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <div className="items-center justify-center">
              <p className="text-gray-600 mb-2 text-center">
                If you do this, all the notes that have been deleted/moved to
                the bin will be restored.
              </p>
              <button
                className="flex text-md font-medium text-gray-700 p-2 mx-auto w-full md:w-auto rounded hover:bg-gray-400 hover:text-white ease-in-out duration-200"
                onClick={() => handleRestoreAll()}
              >
                <AiOutlineUndo className="mt-[0.24rem] mr-1" />
                Restore all notes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Binned notes disclaimer */}
      <p className="block text-gray-600 mx-auto mt-2 -mb-4 text-center text-sm w-fit">
        Binned notes will be{" "}
        <span className="italic font-semibold">permanently deleted </span>
        after{" "}
        <span className="font-medium">{permanentDeletionSetting} days.</span>
      </p>

      {/* Render binned notes */}
      <Note
        showStar={false}
        showCopy={false}
        showEdit={false}
        showLabels={false}
        showDelete={false}
        showArchive={false}
        showPDAndRestore={true}
        userNotes={binnedNotes}
        onNoteRestore={handleNoteRestore}
        onNotePermanentDelete={handlePermanentDelete}
      />
    </div>
  );
}

export default StarNoteBin;
