import { useState } from "react";
import { TbSocial } from "react-icons/tb";
import { BsBriefcase } from "react-icons/bs";
import { TiArrowDown } from "react-icons/ti";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import "../appComponents/career_content/careerCCCCardBanner.css";
import "../appComponents/career_content/careerSMMCardBanner.css";

function ExpandedCareersView() {
  let location = useLocation();
  const [isCCCDownloaded, setIsCCCDownloaded] = useState(false);
  const [isSMMDownloaded, setIsSMMDownloaded] = useState(false);

  // CEO/CTO/CO-FOUNDER document download
  const handleCCCDownload = () => {
    const link = document.createElement("a");
    link.href = "/Position Proposition for Starrvault (CEO CTO CO-FOUNDER).pdf";
    link.download =
      "Position Proposition for Starrvault (CEO CTO CO-FOUNDER).pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsCCCDownloaded(true);
    setTimeout(() => setIsCCCDownloaded(false), 3000);
  };

  // Social-Media-Manager document download
  const handleSMMDownload = () => {
    const link = document.createElement("a");
    link.href =
      "/Position Proposition for Starrvault (Social Media Manager).pdf";
    link.download =
      "Position Proposition for Starrvault (Social Media Manager).pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsSMMDownloaded(true);
    setTimeout(() => setIsSMMDownloaded(false), 3000);
  };

  return (
    <div>
      {location.pathname === "/careers/coo-cto-cofounder" ? (
        // Expanded view for COO/CTO/CO-FOUNDER
        <div className="flex justify-center items-center mt-6 px-4">
          <div className="max-w-[calc(100vw-55rem)] w-full relative">
            <div className="w-24 h-24 text-4xl mx-auto -mb-12 z-50 rounded-full gray-circle relative flex items-center justify-center">
              <BsBriefcase className="h-12 w-12 text-gray-700" />
            </div>
            <div className="w-full h-[15rem] elegant-gradient mx-auto relative" />
            <h1 className="text-4xl font-doto mt-4 text-gray-700 tracking-wide text-center">
              CEO/CTO/CO-FOUNDER
            </h1>
            <div className="mt-6 text-left space-y-4">
              <div className="flex justify-between items-center space-x-8">
                <ul className="flex flex-col w-[45%] space-y-2">
                  <li>
                    <span className="font-semibold">Location: </span>
                    Flexible (with travel to Pune, Mumbai, Bangalore, Delhi)
                  </li>
                  <li>
                    <span className="font-semibold">Position Type: </span>
                    Founding Role (Equity-based initially)
                  </li>
                  <li>
                    <span className="font-semibold">Contact: </span>
                    <a
                      href="mailto:starrvault24@gmail.com"
                      className="text-blue-600 hover:underline"
                    >
                      starrvault24@gmail.com
                    </a>
                  </li>
                </ul>

                {/* Download document */}
                <div className="flex flex-col p-3 items-center rounded-lg border border-gray-300">
                  <span>Download Document:</span>
                  <button
                    onClick={handleCCCDownload}
                    className="flex text-gray-100 bg-gray-700 w-40 h-10 mt-1 border rounded-lg justify-center items-center relative"
                  >
                    {!isCCCDownloaded ? (
                      <>
                        <TiArrowDown className="text-blue-400 text-2xl mr-2" />
                        Download
                      </>
                    ) : (
                      <FaCheckCircle className="text-green-500 text-2xl transition-opacity duration-300 opacity-100" />
                    )}
                  </button>
                </div>
              </div>

              {/* Detailed info section */}
              <div className="text-gray-800">
                {/* About */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  About Starrvault:
                </h2>
                <p className="ml-8 mt-2 ">
                  Starrvault is an emerging platform that unifies communication,
                  organization, and connection management into one secure,
                  highly customizable ecosystem. We're on a mission to reshape
                  how individuals and businesses manage their digital
                  interactions through Star Chat, Star Note, and Star Connect.
                </p>

                {/* Role Overview */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  Role Overview:
                </h2>
                <p className="ml-8 mt-1">
                  We are seeking a driven, visionary individual to join
                  Starrvault as a Chief Operating Officer (COO), Chief
                  Technology Officer (CTO), or Co-Founder, depending on your
                  skills, commitment, and contribution to our growth. This role
                  is not just a job—it's an opportunity to co-build a product
                  with massive potential from the ground up.
                </p>
                <p className="ml-8 mt-1">
                  You'll work closely with me to drive Starrvault's growth,
                  secure funding, and lay the foundation for scaling operations.
                  As we progress, formal titles and responsibilities will evolve
                  based on your role in the company's development.
                </p>

                {/* Key Responsibilities */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  Key Responsibilities:
                </h2>
                <ul className="list-disc pl-6 ml-8 mt-2 space-y-1">
                  <li>
                    <span className="font-semibold">Strategic Growth:</span>{" "}
                    Shape and execute Starrvault's growth strategy alongside the
                    founder.
                  </li>
                  <li>
                    <span className="font-semibold">Fundraising:</span>{" "}
                    Participate in securing investments, including traveling to
                    key cities to meet angel investors.
                  </li>
                  <li>
                    <span className="font-semibold">
                      Operations Management (COO):
                    </span>{" "}
                    Oversee day-to-day operations, streamline processes, and
                    drive efficiency.
                  </li>
                  <li>
                    <span className="font-semibold">
                      Technical Leadership (CTO):
                    </span>{" "}
                    Lead technical development, manage product roadmap, and
                    ensure scalability.
                  </li>
                  <li>
                    <span className="font-semibold">Team Building:</span> Help
                    recruit, manage, and inspire a team as we expand.
                  </li>
                  <li>
                    <span className="font-semibold">Networking:</span> Build
                    span relationships with stakeholders, partners, and
                    investors.
                  </li>
                </ul>

                {/* Our criteria */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  What We're Looking For:
                </h2>
                <ul className="list-disc pl-6 ml-8 mt-2 space-y-1">
                  <li>
                    <span className="font-semibold">
                      Entrepreneurial Spirit:
                    </span>{" "}
                    A self-starter ready to take ownership and work in an
                    unstructured environment.
                  </li>
                  <li>
                    <span className="font-semibold">Leadership Skills:</span>{" "}
                    span decision-making abilities with experience leading teams
                    or projects.
                  </li>
                  <li>
                    <span className="font-semibold">Adaptability:</span>{" "}
                    Comfortable wearing multiple hats in a dynamic, fast-paced
                    environment.
                  </li>
                  <li>
                    <span className="font-semibold">
                      Technical Acumen (for CTO):
                    </span>{" "}
                    span background in software development, product
                    architecture, or tech leadership.
                  </li>
                  <li>
                    <span className="font-semibold">
                      Operational Excellence (for COO):
                    </span>{" "}
                    Proven track record of managing business operations, scaling
                    teams, or handling strategic growth initiatives.
                  </li>
                  <li>
                    <span className="font-semibold">Commitment:</span>{" "}
                    Willingness to invest time, energy, and passion into
                    Starrvault's success.
                  </li>
                </ul>

                {/* Why join starrvault */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  Why Join Us?
                </h2>
                <ul className="list-disc pl-6 ml-8 mt-2 mb-8 space-y-1">
                  <li>
                    <span className="font-semibold">Founding Equity:</span>{" "}
                    Shape the company's future and share in its success.
                  </li>
                  <li>
                    <span className="font-semibold">Growth Opportunity:</span>{" "}
                    Be part of Starrvault's journey from inception to scale.
                  </li>
                  <li>
                    <span className="font-semibold">Dynamic Work:</span> Engage
                    in meaningful work, from product development to investor
                    meetings.
                  </li>
                  <li>
                    <span className="font-semibold">Personal Impact:</span>{" "}
                    Build something transformative while making your mark in the
                    startup ecosystem.
                  </li>
                </ul>
              </div>

              {/* Centered Call-to-Action */}
              <p className="text-center font-medium text-gray-800">
                If this excites you and you're ready to embark on a challenging
                yet rewarding journey, reach out to
                <a
                  href="mailto:starrvault24@gmail.com"
                  className="text-blue-600 hover:underline"
                >
                  {" "}
                  starrvault24@gmail.com
                </a>
              </p>
              <p className="w-[45rem] text-center mx-auto text-sm text-gray-700">
                <span className="text-red-500 mr-[0.15rem]">*</span>
                Please note that the text on this page and the document's
                contents are solely for informational purposes. They are not
                intended to serve as legal advice or have any legal
                significance.{" "}
              </p>
            </div>
          </div>
        </div>
      ) : location.pathname === "/careers/social-media-manager" ? (
        // Expanded view for SOCIAL MEDIA MANAGER
        <div className="flex justify-center items-center mt-6 px-4">
          <div className="max-w-[calc(100vw-55rem)] w-full relative">
            <div className="w-24 h-24 text-4xl mx-auto -mb-12 z-50 rounded-full gray-circle relative flex items-center justify-center">
              <TbSocial className="h-12 w-12 text-gray-700" />
            </div>
            <div className="w-[calc(100vw-55rem)] h-[15rem] vibrant-gradient mx-auto relative flex items-center justify-center rounded-lg shadow-md" />
            <h1 className="text-4xl font-doto mt-4 text-gray-700 tracking-wide text-center">
              SOCIAL MEDIA MANAGER
            </h1>
            <div className="mt-6 text-left space-y-4">
              <div className="flex justify-between items-center space-x-8">
                <ul className="flex flex-col w-[45%] space-y-2">
                  <li>
                    <span className="font-semibold">Location: </span>
                    Remote/Flexible
                  </li>
                  <li>
                    <span className="font-semibold">Position Type: </span>
                    Part-Time/Contract (with potential for full-time)
                  </li>
                  <li>
                    <span className="font-semibold">Contact: </span>
                    <a
                      href="mailto:starrvault24@gmail.com"
                      className="text-blue-600 hover:underline"
                    >
                      starrvault24@gmail.com
                    </a>
                  </li>
                </ul>
                {/* Download document */}
                <div className="flex flex-col p-3 items-center rounded-lg border border-gray-300">
                  <span>Download Document:</span>
                  <button
                    onClick={handleSMMDownload}
                    className="flex text-gray-100 bg-gray-700 w-40 h-10 mt-1 border rounded-lg justify-center items-center relative"
                  >
                    {!isSMMDownloaded ? (
                      <>
                        <TiArrowDown className="text-blue-400 text-2xl mr-2" />
                        Download
                      </>
                    ) : (
                      <FaCheckCircle className="text-green-500 text-2xl transition-opacity duration-300 opacity-100" />
                    )}
                  </button>
                </div>
              </div>

              {/* Detailed info section */}
              <div className="text-gray-800">
                {/* About */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  About Starrvault:
                </h2>
                <p className="ml-8 mt-2 ">
                  Starrvault is an emerging platform that unifies communication,
                  organization, and connection management into one secure,
                  highly customizable ecosystem. We're on a mission to reshape
                  how individuals and businesses manage their digital
                  interactions through Star Chat, Star Note, and Star Connect.
                </p>

                {/* Role Overview */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  Role Overview:
                </h2>
                <p className="ml-8 mt-1">
                  We're looking for a creative, self-driven Social Media Manager
                  to lead Starrvault's online presence. This role goes beyond
                  simply posting content—you'll be responsible for designing
                  engaging Instagram posts/reels and creating promotional and
                  feature-focused content for LinkedIn.
                </p>
                <p className="ml-8 mt-1">
                  You'll have the freedom to bring fresh ideas, develop unique
                  content strategies, and showcase Starrvault's value to a
                  growing audience. As we scale, your role will be pivotal in
                  shaping how Starrvault connects with the world.
                </p>

                {/* Key Responsibilities */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  Key Responsibilities:
                </h2>
                <ul className="list-disc pl-6 ml-8 mt-2 space-y-1">
                  <li>
                    <span className="font-semibold">Content Creation:</span>{" "}
                    Design visually appealing posts, reels, and videos for
                    Instagram and LinkedIn, aligned with Starrvault's brand.
                  </li>
                  <li>
                    <span className="font-semibold">Posting & Scheduling:</span>{" "}
                    Manage posting schedules—either sending content for approval
                    or posting directly at designated times.
                  </li>
                  <li>
                    <span className="font-semibold">
                      Creative Brainstorming:
                    </span>{" "}
                    Generate new ideas for posts, campaigns, and video content
                    to promote Starrvault's features, updates, and milestones.
                  </li>
                  <li>
                    <span className="font-semibold">Engagement:</span> Monitor
                    social media channels, engage with the community, and track
                    performance metrics to optimize content strategies.
                  </li>
                  <li>
                    <span className="font-semibold">Trend Awareness:</span> Stay
                    updated with social media trends to ensure Starrvault's
                    content remains fresh, relevant, and engaging.
                  </li>
                  <li>
                    <span className="font-semibold">Networking:</span> Build
                    span relationships with stakeholders, partners, and
                    investors.
                  </li>
                </ul>

                {/* Our criteria */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  What We're Looking For:
                </h2>
                <ul className="list-disc pl-6 ml-8 mt-2 space-y-1">
                  <li>
                    <span className="font-semibold">Creativity:</span> Strong
                    design skills with experience in creating social media
                    content (IG posts, reels, LinkedIn banners, etc.).
                  </li>
                  <li>
                    <span className="font-semibold">Tools Proficiency:</span>{" "}
                    Familiarity with design tools (Canva, Figma, Adobe Suite,
                    etc.) and basic video editing apps/software.
                  </li>
                  <li>
                    <span className="font-semibold">Adaptability:</span>{" "}
                    Comfortable wearing multiple hats in a dynamic, fast-paced
                    environment.
                  </li>
                  <li>
                    <span className="font-semibold">Commitment:</span>{" "}
                    Willingness to invest time, energy, and passion into
                    Starrvault's success.
                  </li>
                </ul>

                {/* Why join starrvault */}
                <h2 className="text-3xl font-medium font-nserif mt-10">
                  Why Join Us?
                </h2>
                <ul className="list-disc pl-6 ml-8 mt-2 mb-10 space-y-1">
                  <li>
                    <span className="font-semibold">Creative Freedom:</span>{" "}
                    Flexibility to bring your ideas to life and shape
                    Starrvault's digital identity.
                  </li>
                  <li>
                    <span className="font-semibold">Growth Opportunity:</span>{" "}
                    Be part of Starrvault's journey from inception to scale.
                  </li>
                  <li>
                    <span className="font-semibold">Dynamic Environment:</span>{" "}
                    Collaborate closely with the founder, contributing to both
                    marketing strategies and product promotion. meetings.
                  </li>
                </ul>
              </div>
              
              {/* Centered Call-to-Action */}
              <p className="text-center font-medium text-gray-800">
                If this excites you and you're ready to embark on a challenging
                yet rewarding journey, reach out to
                <a
                  href="mailto:starrvault24@gmail.com"
                  className="text-blue-600 hover:underline"
                >
                  {" "}
                  starrvault24@gmail.com
                </a>
              </p>
              <p className="w-[45rem] text-center mx-auto text-sm text-gray-700">
                <span className="text-red-500 mr-[0.15rem]">*</span>
                Please note that the text on this page and the document's
                contents are solely for informational purposes. They are not
                intended to serve as legal advice or have any legal
                significance.{" "}
              </p>
            </div>
          </div>
        </div>
      ) : (
        // Not found page
        <div className="text-center">
          <h1 className="text-4xl text-gray-600">Not Found</h1>
          <p className="text-md text-gray-700">
            Sorry, the position you are looking for was not found.
          </p>
        </div>
      )}
    </div>
  );
}

export default ExpandedCareersView;
