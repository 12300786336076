import React from "react";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsBriefcase } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FiEdit, FiMessageSquare, FiUsers } from "react-icons/fi";

function SettingsSidebar() {
  return (
    <div className="w-[10rem] flex flex-col h-full">
      {/* Sidebar Heading */}
      <h2 className="text-custom-gray-1 text-2xl font-medium mb-4">Settings</h2>

      {/* Menu List */}
      <ul className="-ml-2 w-[10rem] flex-grow flex flex-col gap-y-1">
        {/* Account */}
        <Link to="/settings/account" className="text-md font-medium">
          <li className="flex items-center text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <FaUser className="mr-2" />
            Account
          </li>
        </Link>

        {/* Star Chat */}
        <Link to="/settings/starchat" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiMessageSquare className="mr-2" />
              Star Chat
            </div>
          </li>
        </Link>

        {/* Star Note */}
        <Link to="/settings/starnote" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiEdit className="mr-2" />
              Star Note
            </div>
          </li>
        </Link>

        {/* Star Connect */}
        <Link to="/settings/starconnect" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiUsers className="mr-2" />
              Star Connect
            </div>
          </li>
        </Link>
      </ul>

      {/* Navigation links */}
      <div className="flex flex-col">
        <ul className=" w-[10rem] flex-grow flex flex-col gap-y-1">
          {/* Documentation */}
          <Link to="/documentation">
            <li className="flex items-center justify-start text-sm text-gray-600 hover:bg-gray-100 p-[0.40rem] rounded cursor-pointer transition duration-200">
              <IoDocumentTextOutline className="mr-2 text-lg" />
              See Docs
            </li>
          </Link>
          {/* About */}
          <Link to="/about">
            <li className="flex items-center justify-start text-sm text-gray-600 hover:bg-gray-100 p-[0.40rem] rounded cursor-pointer transition duration-200">
              <MdOutlineInfo className="mr-2 text-lg" />
              About
            </li>
          </Link>
          {/* Careers */}
          <Link to="/careers">
            <li className="flex items-center justify-start text-sm text-gray-600 hover:bg-gray-100 p-[0.40rem] rounded cursor-pointer transition duration-200">
              <BsBriefcase className="mr-2 text-lg" />
              Careers
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default SettingsSidebar;
