import {
  AiFillStar,
  AiOutlineStar,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { format } from "date-fns";
import { db } from "../../../firebase";
import { GoLinkExternal } from "react-icons/go";
import { RiExchangeLine } from "react-icons/ri";
import { IoWarningOutline } from "react-icons/io5";
import AddContact from "../../components/AddContact";
import { Link, useNavigate } from "react-router-dom";
import SocialBlocks from "../../components/SocialBlocks";
import SocialBanners from "../../components/SocialBanners";
import { AuthContext } from "../../../context/AuthContext";
import { Base64Util } from "../../../utils/B64EncodeAndDecode";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useState, useEffect, useContext, useRef } from "react";
import { FiArrowLeft, FiX, FiCheck, FiTrash } from "react-icons/fi";
import { MdArchive, MdOutlineShare, MdUnarchive } from "react-icons/md";

function ExpandedContactView({
  selectedContact,
  isAddingContact,
  onContactArchived,
  setIsAddingContact,
  handleNavigateHome,
  currentSystemEncryption,
  currentContactAvatarPreference,
}) {
  let navigate = useNavigate();
  const EBModalRef = useRef(null);
  const EEModalRef = useRef(null);
  const socialsModalRef = useRef(null);
  const contactDeletionModalRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const dbName = "contacts-" + currentUser.email;
  const [isEditing, setIsEditing] = useState(false);
  const [socialsModal, setSocialsModal] = useState(false);
  const [localContact, setLocalContact] = useState(selectedContact);
  const [editedContact, setEditedContact] = useState(localContact);
  const [contactDeletionModal, setContactDeletionModal] = useState(false);
  const [encryptionErrorModal, setEncryptionErrorModal] = useState(false);
  const [encryptionBannerModal, setEncryptionBannerModal] = useState(false);

  // Update local contact state whenever selectedContact prop changes
  useEffect(() => {
    setLocalContact(selectedContact);
    setEditedContact(selectedContact);
  }, [selectedContact]);

  // Handle clicks outside modals and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (contactDeletionModalRef.current &&
          !contactDeletionModalRef.current.contains(event.target)) ||
        (EBModalRef.current && !EBModalRef.current.contains(event.target)) ||
        (EEModalRef.current && !EEModalRef.current.contains(event.target)) ||
        (socialsModalRef.current &&
          !socialsModalRef.current.contains(event.target))
      ) {
        setContactDeletionModal(false);
        setEncryptionBannerModal(false);
        setEncryptionErrorModal(false);
        setSocialsModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setContactDeletionModal(false);
        setEncryptionBannerModal(false);
        setEncryptionErrorModal(false);
        setSocialsModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Function to convert retrieved timestamp to formatted date string
  const formatDate = (timestamp) => {
    if (!timestamp) return "-";
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    return format(date, "PP");
  };

  // Function to star the selected contact
  const handleContactStarring = async () => {
    const updateContactStatus =
      localContact.contactStatus === "starred" ? "active" : "starred";
    await updateDoc(doc(db, dbName, localContact.id), {
      contactStatus: updateContactStatus,
    });
    setLocalContact((prevContact) => ({
      ...prevContact,
      contactStatus: updateContactStatus,
    }));
  };

  // Function to archive the selected contact
  const handleContactArchiving = async () => {
    await updateDoc(doc(db, dbName, localContact.id), {
      contactStatus: "archived",
    });
    onContactArchived(localContact.id);
  };

  // Function to archive the selected contact
  const handleContactUnarchiving = async () => {
    await updateDoc(doc(db, dbName, localContact.id), {
      contactStatus: "active",
    });
    onContactArchived(localContact.id);
    navigate("/starconnect/archive");
  };

  // Handle contact deletion
  const handleContactDeletion = async () => {
    setContactDeletionModal(false);
    await deleteDoc(doc(db, dbName, localContact.id));
    onContactArchived(localContact.id);
    navigate("/starconnect");
  };

  // Save the changes made to the contact
  const handleSave = async () => {
    await updateDoc(doc(db, dbName, localContact.id), editedContact);
    setLocalContact(null);
    navigate("/starconnect");
    setIsEditing(false);
  };

  // Handle changes to the inputs
  const handleChange = (field, value) => {
    setEditedContact((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle copying of text to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  // Handle the conversion of contact encryption to Base64
  const convertToBase64 = async () => {
    if (selectedContact.contactEncryption === "base64") return;

    // Fields to encode in B64
    const fieldsToEncode = [
      "address",
      "email",
      "firstName",
      "lastName",
      "phone",
    ];

    // Update the contact with encoded fields
    const updatedContact = {
      ...selectedContact,
      ...fieldsToEncode.reduce((encodedFields, field) => {
        if (selectedContact[field]) {
          encodedFields[field] = Base64Util.encode(selectedContact[field]);
        }
        return encodedFields;
      }, {}),
      contactEncryption: "base64",
    };

    // Update the contact
    await updateDoc(doc(db, dbName, updatedContact.id), updatedContact);

    setLocalContact(updatedContact);
    setEncryptionErrorModal(false);
  };

  // Handle the conversion of contact encryption to aes256
  const convertToAES256 = () => {
    return;
  };

  return (
    <div className="flex-1 h-[calc(100vh-1.8rem)] p-4 border border-gray-200 relative">
      {isAddingContact ? (
        <AddContact
          toggle={setIsAddingContact}
          currentSystemEncryption={currentSystemEncryption}
        />
      ) : localContact ? (
        <>
          <button onClick={handleNavigateHome}>
            <FiArrowLeft
              className="mt-2 ml-2 mb-5 cursor-pointer rounded-full text-gray-600"
              size="20"
              title="Go back"
            />
          </button>

          {/* Contact topbar */}
          <div className="flex items-center ml-4 mb-4">
            <div
              className={`relative h-32 w-32 mr-3 rounded-full flex items-center justify-center ${
                currentContactAvatarPreference === "filled-color"
                  ? localContact.avatarColor === "green"
                    ? "bg-green-500"
                    : localContact.avatarColor === "red"
                    ? "bg-red-500"
                    : localContact.avatarColor === "blue"
                    ? "bg-blue-500"
                    : localContact.avatarColor === "purple"
                    ? "bg-purple-500"
                    : localContact.avatarColor === "pink"
                    ? "bg-pink-500"
                    : "bg-gray-300"
                  : ""
              }`}
            >
              {/* Gradient Ring */}
              {currentContactAvatarPreference === "gradient-ring" && (
                <div
                  className={`absolute inset-0 rounded-full ${
                    localContact.contactEncryption === "base64" ||
                    localContact.contactEncryption === "aes256"
                      ? "bg-gradient-to-r from-yellow-200 via-purple-400 to-blue-500 p-1"
                      : "bg-gray-300 p-[0.18rem]"
                  }`}
                >
                  <div
                    className="h-full w-full rounded-full bg-white"
                    style={{
                      backgroundColor: "transperent",
                    }}
                  >
                    <span className="text-gray-600 text-4xl font-semibold flex items-center justify-center h-full">
                      {localContact.contactEncryption === "base64"
                        ? Base64Util.decode(localContact.firstName)[0] +
                          (localContact.lastName
                            ? Base64Util.decode(localContact.lastName)[0]
                            : "")
                        : localContact.firstName[0] +
                          (localContact.lastName
                            ? localContact.lastName[0]
                            : "")}
                    </span>
                  </div>
                </div>
              )}

              {/* Filled Color */}
              <span className="text-white text-4xl font-semibold flex items-center justify-center h-full">
                {localContact.contactEncryption === "base64"
                  ? Base64Util.decode(localContact.firstName)[0] +
                    (localContact.lastName
                      ? Base64Util.decode(localContact.lastName)[0]
                      : "")
                  : localContact.firstName[0] +
                    (localContact.lastName ? localContact.lastName[0] : "")}
              </span>
            </div>

            {/* Contact Name */}
            <h2 className="text-3xl font-medium text-gray-700">
              {localContact.contactEncryption === "base64"
                ? Base64Util.decode(localContact.firstName) +
                  " " +
                  Base64Util.decode(localContact.lastName)
                : localContact.firstName + " " + localContact.lastName}
            </h2>

            {/* Option Menu */}
            <div className="flex absolute top-4 right-6 space-x-6">
              {/* Encryption type */}
              {localContact.contactEncryption === currentSystemEncryption && (
                <div
                  className="float-left text-sm font-medium rounded-md p-1 ml-4 mt-1 mb-3 cursor-pointer"
                  style={
                    localContact.contactEncryption === "base64" ||
                    localContact.contactEncryption === "aes256"
                      ? {
                          background:
                            "linear-gradient(135deg, #fff1b5, #c0a3fd, #7cadff)",
                          boxShadow: "0px 4px 6px rgba(128, 128, 128, 0.5)",
                        }
                      : { background: "#E5E7EB", borderRadius: "6px" }
                  }
                  onClick={
                    localContact.contactEncryption === "base64" ||
                    localContact.contactEncryption === "aes256"
                      ? () => setEncryptionBannerModal(true)
                      : null
                  }
                >
                  <span className="font-normal text-gray-600">encryption:</span>{" "}
                  <span
                    className={
                      localContact.contactEncryption === "base64" ||
                      localContact.contactEncryption === "aes256"
                        ? "text-white"
                        : "text-gray-700"
                    }
                  >
                    {localContact.contactEncryption
                      ? localContact.contactEncryption === "base64"
                        ? "Base 64"
                        : localContact.contactEncryption === "aes256"
                        ? "AES 256"
                        : "None"
                      : "None"}
                  </span>
                </div>
              )}

              {/* Encryption error banner */}
              {localContact.contactEncryption !== currentSystemEncryption && (
                <div
                  className="flex float-left bg-yellow-100 text-yellow-700 text-sm font-medium shadow-md rounded-md p-[0.30rem] ml-4 mt-1 mb-3 cursor-pointer"
                  onClick={() => setEncryptionErrorModal(true)}
                >
                  <IoWarningOutline className="mt-[0.15rem] mr-1" />
                  <span>Contact encryption error</span>
                </div>
              )}

              {/* Accessibility icons */}
              <div
                className="text-gray-600 cursor-pointer pt-2"
                onClick={handleContactStarring}
              >
                {localContact.contactStatus === "starred" ? (
                  <AiFillStar
                    size={23}
                    className="text-yellow-400"
                    title="Unstar"
                  />
                ) : (
                  <AiOutlineStar size={23} title="Star" />
                )}
              </div>
              <div className="text-gray-600 font-medium cursor-pointer pt-2">
                <MdOutlineShare size={23} title="Share contact" />
              </div>
              <div className="text-gray-600 cursor-pointer pt-2">
                {localContact.contactStatus === "archived" ? (
                  <MdUnarchive
                    size={23}
                    title="Unarchive"
                    onClick={handleContactUnarchiving}
                  />
                ) : (
                  <MdArchive
                    size={23}
                    title="Archive"
                    onClick={handleContactArchiving}
                  />
                )}
              </div>
              <div
                className="text-gray-600 hover:text-red-600 cursor-pointer pt-2"
                onClick={() => setContactDeletionModal(true)}
              >
                <AiOutlineDelete size={23} title="Delete" />
              </div>
            </div>
          </div>

          {/* Contact Information */}
          <div className="flex justify-between">
            {/* Contact Info Card */}
            <div className="bg-white w-[calc(100vw-67rem)] p-4 mx-4 mt-6 rounded-xl border border-gray-200">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-semibold text-gray-600">
                  Contact Info
                </h3>
                {isEditing ? (
                  <div className="flex ml-auto -mt-6 -mr-2 space-x-3">
                    <FiCheck
                      size={18}
                      className="text-green-600 cursor-pointer"
                      onClick={handleSave}
                      title="Save changes"
                    />
                    <FiX
                      size={18}
                      className="text-gray-600 cursor-pointer"
                      onClick={() => setIsEditing(false)}
                      title="Cancel editing"
                    />
                  </div>
                ) : (
                  <AiOutlineEdit
                    className="text-gray-500 hover:text-gray-700 -mt-6 -mr-2 transition-colors cursor-pointer"
                    size={18}
                    title="Edit contact"
                    onClick={() => setIsEditing(true)}
                  />
                )}
              </div>

              {/* Contact Info Fields */}
              <ul className="space-y-2 ml-3">
                {/* First name */}
                <li
                  className={`${
                    isEditing ? "flex" : "hidden"
                  } items-center text-gray-600`}
                >
                  {isEditing ? (
                    <>
                      <span className="w-24 font-semibold">First name:</span>
                      <input
                        type="text"
                        placeholder="Enter first name"
                        className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={
                          isEditing &&
                          selectedContact.contactEncryption === "base64"
                            ? Base64Util.decode(editedContact.firstName)
                            : editedContact.firstName
                        }
                        onChange={(e) =>
                          handleChange(
                            "firstName",
                            selectedContact.contactEncryption === "base64"
                              ? Base64Util.encode(e.target.value)
                              : e.target.value
                          )
                        }
                      />
                    </>
                  ) : null}
                </li>

                {/* Last name */}
                <li
                  className={`${
                    isEditing ? "flex" : "hidden"
                  } items-center text-gray-600`}
                >
                  {isEditing ? (
                    <>
                      <span className="w-24 font-semibold">Last name:</span>
                      <input
                        type="text"
                        placeholder="Enter last name"
                        className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={
                          isEditing &&
                          selectedContact.contactEncryption === "base64"
                            ? Base64Util.decode(editedContact.lastName)
                            : editedContact.lastName
                        }
                        onChange={(e) =>
                          handleChange(
                            "lastName",
                            selectedContact.contactEncryption === "base64"
                              ? Base64Util.encode(e.target.value)
                              : e.target.value
                          )
                        }
                      />
                    </>
                  ) : null}
                </li>

                {/* Phone */}
                {isEditing || localContact.phone ? (
                  <li className="flex items-center text-gray-600">
                    {isEditing ? (
                      <>
                        <span className="w-24 font-semibold">Phone:</span>
                        <input
                          type="text"
                          placeholder="Enter phone number"
                          className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          value={
                            selectedContact.contactEncryption === "base64"
                              ? Base64Util.decode(editedContact.phone)
                              : editedContact.phone
                          }
                          onChange={(e) =>
                            handleChange(
                              "phone",
                              selectedContact.contactEncryption === "base64"
                                ? Base64Util.encode(e.target.value)
                                : e.target.value
                            )
                          }
                        />
                      </>
                    ) : (
                      <>
                        <span className="w-24 font-semibold">Phone:</span>
                        <span
                          className="hover:text-gray-600 transition-colors cursor-pointer"
                          title="Copy phone number"
                          onClick={() =>
                            handleCopy(
                              localContact.contactEncryption === "base64"
                                ? Base64Util.decode(localContact.phone)
                                : localContact.phone
                            )
                          }
                        >
                          {localContact.contactEncryption === "base64"
                            ? Base64Util.decode(localContact.phone)
                            : localContact.phone}
                        </span>
                      </>
                    )}
                  </li>
                ) : null}

                {/* Email */}
                {isEditing || localContact.email ? (
                  <li className="flex items-center text-gray-600">
                    {isEditing ? (
                      <>
                        <span className="w-24 font-semibold">Email:</span>
                        <input
                          type="email"
                          placeholder="Enter email"
                          className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          value={
                            isEditing &&
                            selectedContact.contactEncryption === "base64"
                              ? Base64Util.decode(editedContact.email)
                              : editedContact.email
                          }
                          onChange={(e) =>
                            handleChange(
                              "email",
                              selectedContact.contactEncryption === "base64"
                                ? Base64Util.encode(e.target.value)
                                : e.target.value
                            )
                          }
                        />
                      </>
                    ) : (
                      localContact.email && (
                        <>
                          <span className="w-24 font-semibold">Email:</span>
                          <span
                            className="flex-1 group relative hover:text-gray-600 transition-colors cursor-pointer"
                            title="Copy email"
                            onClick={() =>
                              handleCopy(
                                localContact.contactEncryption === "base64"
                                  ? Base64Util.decode(localContact.email)
                                  : localContact.email
                              )
                            }
                          >
                            {localContact.contactEncryption === "base64"
                              ? Base64Util.decode(localContact.email)
                              : localContact.email}
                          </span>
                        </>
                      )
                    )}
                  </li>
                ) : null}

                {/* Address */}
                {isEditing || localContact.address ? (
                  <li className="flex items-center text-gray-600">
                    {isEditing ? (
                      <>
                        <span className="w-24 font-semibold">Address:</span>
                        <input
                          type="text"
                          placeholder="Enter address"
                          className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          value={
                            isEditing &&
                            selectedContact.contactEncryption === "base64"
                              ? Base64Util.decode(editedContact.address)
                              : editedContact.address
                          }
                          onChange={(e) =>
                            handleChange(
                              "address",
                              selectedContact.contactEncryption === "base64"
                                ? Base64Util.encode(e.target.value)
                                : e.target.value
                            )
                          }
                        />
                      </>
                    ) : (
                      localContact.address && (
                        <>
                          <span className="w-24 font-semibold">Address:</span>
                          <span
                            className="flex-1 group relative hover:text-gray-600 transition-colors cursor-pointer"
                            title="Copy address"
                            onClick={() =>
                              handleCopy(
                                localContact.contactEncryption === "base64"
                                  ? Base64Util.decode(localContact.address)
                                  : localContact.address
                              )
                            }
                          >
                            {localContact.contactEncryption === "base64"
                              ? Base64Util.decode(localContact.address)
                              : localContact.address}
                          </span>
                        </>
                      )
                    )}
                  </li>
                ) : null}

                {/* Birthday */}
                {isEditing || localContact.birthday ? (
                  <li className="flex items-center text-gray-600">
                    {isEditing ? (
                      <>
                        <span className="w-24 font-semibold">Birthday:</span>
                        <input
                          type="date"
                          className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          value={formatDate(localContact.birthday)}
                          onChange={(e) =>
                            handleChange("birthday", e.target.value)
                          }
                        />
                      </>
                    ) : (
                      localContact.birthday && (
                        <>
                          <span className="w-24 font-semibold">Birthday:</span>
                          <span
                            className="flex-1 group relative hover:text-gray-600 transition-colors cursor-pointer"
                            title="Copy birthday"
                            onClick={() =>
                              handleCopy(formatDate(localContact.birthday))
                            }
                          >
                            {formatDate(localContact.birthday)}
                          </span>
                        </>
                      )
                    )}
                  </li>
                ) : null}

                {/* Avatar color */}
                <li
                  className={`${
                    isEditing ? "flex" : "hidden"
                  } items-center text-gray-600`}
                >
                  {isEditing ? (
                    <>
                      <span className="w-24 font-semibold">Avatar color:</span>
                      <select
                        className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={editedContact.avatarColor}
                        onChange={(e) =>
                          handleChange("avatarColor", e.target.value)
                        }
                      >
                        <option value="green">Green</option>
                        <option value="red">Red</option>
                        <option value="blue">Blue</option>
                        <option value="purple">Purple</option>
                        <option value="pink">Pink</option>
                      </select>
                    </>
                  ) : null}
                </li>
              </ul>
            </div>

            {/* Socials Card */}
            <div className="bg-white w-full sm:w-auto min-w-[22rem] h-[7rem] overflow-y-hidden p-3 mx-4 mt-6 rounded-xl border border-gray-200">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-semibold text-gray-600">Socials</h3>
                <GoLinkExternal
                  className="text-gray-500 hover:text-gray-700 -mt-3 transition-colors cursor-pointer"
                  size={15}
                  title="See more"
                  onClick={() => setSocialsModal(true)}
                />
              </div>
              <SocialBlocks content={localContact.socials} />
            </div>
          </div>
        </>
      ) : (
        // Resting info page
        <div className="flex justify-center items-center min-h-[calc(100vh-2rem)]">
          <div className="text-center">
            <h1 className="text-4xl font-semibold text-gray-800 mb-8">
              Connections, secured
            </h1>
            <ul className="space-y-4 text-lg">
              <li>
                <Link to="/settings/starconnect" className="text-blue-500">
                  Manage Settings
                </Link>
              </li>
              <li>
                <Link to="/starconnect/archive" className="text-blue-500">
                  See Archive
                </Link>
              </li>
              <li>
                <Link to="/documentation/starconnect" className="text-blue-500">
                  See Docs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Socials modal */}
      {socialsModal && (
        <div className="fixed inset-0 w-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={socialsModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[25%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold">Socials</h2>
              <FiX
                className="text-lg cursor-pointer text-gray-600"
                onClick={() => setSocialsModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 border-t bg-gray-300" />
            <SocialBanners
              content={localContact.socials}
              docId={localContact.id}
            />
          </div>
        </div>
      )}

      {/* Encryption banner info modal */}
      {encryptionBannerModal &&
        (localContact.contactEncryption === "base64" ||
          localContact.contactEncryption === "aes256") && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              ref={EBModalRef}
              className="relative text-white bg-gradient-to-br from-[#fbe99a] via-[#c0a3fd] to-[#7cadff] rounded-lg shadow-md p-4 w-[25%]"
            >
              <div className="flex justify-between items-center mb-3 px-1">
                <h2 className="text-lg font-semibold text-gray-700">
                  Contact Encryption
                </h2>
                <FiX
                  className="text-lg cursor-pointer text-gray-700"
                  onClick={() => setEncryptionBannerModal(false)}
                  title="Close"
                />
              </div>
              <div className="-mx-4 border bg-white" />
              <p className="text-gray-800 max-w-[35rem] my-3 text-center">
                Information about this contact is saved with{" "}
                <span className="font-semibold text-indigo-600">
                  {localContact.contactEncryption === "none"
                    ? "None"
                    : localContact.contactEncryption === "base64"
                    ? "Base 64"
                    : localContact.contactEncryption === "aes256"
                    ? "AES 256"
                    : null}
                </span>
              </p>
              <Link
                to="/documentation/starconnect"
                className="flex items-center justify-center text-blue-600 text-sm hover:underline space-x-1"
              >
                <span>Learn more</span>
                <AiOutlineArrowRight className="text-xs text-blue-600" />
              </Link>
            </div>
          </div>
        )}

      {/* Encryption error modal */}
      {encryptionErrorModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={EEModalRef}
            className="relative text-gray-900 bg-white rounded-lg shadow-md p-4 w-[30%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold">
                Contact Encryption Error
              </h2>
              <FiX
                className="text-lg cursor-pointer"
                onClick={() => setEncryptionErrorModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 border-t bg-gray-300" />
            {/* Disclaimer banner */}
            <div
              className="flex items-center text-center py-[0.50rem] mt-3 text-sm font-medium text-yellow-700 bg-yellow-100 rounded-md shadow-md"
              style={{
                backgroundImage:
                  "repeating-linear-gradient(135deg, #fef9c3 0px, #fef9c3 8px, #fef08a 1px, #fef08a 10px)",
              }}
            >
              <span className="flex mx-auto">
                <IoWarningOutline className="my-auto mr-1" />
                This is a recommended action
              </span>
            </div>
            {/* Info section */}
            <p className="text-gray-800 my-3 text-center">
              All contacts must use the same method of encryption encryption.
              Varying encryption methods could cause errors in{" "}
              <span className="text-red-500 font-medium">
                loading and editing the contact info.
              </span>{" "}
              To{" "}
              <span className="text-green-500  font-medium">
                resolve this error,
              </span>{" "}
              click the button below.
            </p>
            <div className="flex justify-center mt-2">
              <button
                className="flex bg-blue-500 font-medium text-white p-2 rounded shadow-md focus:outline-none"
                onClick={() =>
                  currentSystemEncryption === "base64"
                    ? convertToBase64(localContact)
                    : currentSystemEncryption === "aes256"
                    ? convertToAES256()
                    : null
                }
              >
                <RiExchangeLine className="my-auto mr-1 text-2xl" />
                Change contact encryption to{" "}
                {currentSystemEncryption === "base64"
                  ? "Base 64"
                  : currentSystemEncryption === "aes256"
                  ? "AES 256"
                  : "None"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Contact Deletion Modal */}
      {contactDeletionModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%] space-y-3"
            ref={contactDeletionModalRef}
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Delete Contact
              </h2>
              <FiX
                size={23}
                className="text-lg text-gray-600 cursor-pointer"
                onClick={() => setContactDeletionModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <div className="flex flex-col items-center">
              <p className="text-center text-gray-700 px-1 mb-2">
                Are you sure you want to delete this contact? This action{" "}
                <span className="font-semibold">cannot be undone.</span>
              </p>
              <button
                className="flex text-md font-medium p-2 w-full md:w-auto rounded text-red-500 hover:bg-red-500 hover:text-white ease-in-out duration-200"
                onClick={handleContactDeletion}
              >
                <FiTrash className="mt-[0.2rem] mr-1" />
                Delete Contact
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExpandedContactView;
